body {
    background: #fafafa;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #444;
}

/* ==========================================================================
navbar text
========================================================================== */
.navbar-default .navbar-brand {
    color: #444;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    padding: 5px 15px;
}

.logo .logo-img {
    height: 45px;
    display: inline-block;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.hipster {
    display: inline-block;
    width: 347px;
    height: 497px;
    background: url("../images/hipster.png") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .hipster {
        background: url("../images/hipster2x.png") no-repeat center top;
        background-size: contain;
    }
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break:break-all;
}

.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a {
    color: #1c0cf5;
}

a:hover, .hand, [jh-sort-by] {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display:inline;
    list-style:none;
    margin:0;
    margin-left:15px;
    padding:0;
    vertical-align:2px;
}

.point:last {
    margin:0 !important;
}
.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre{
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: row;
   flex-direction: row;
   -webkit-justify-content: flex-end;
   justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td vertically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus {
    outline: none;
}




/* jhipster-needle-css-add-main JHipster will add new css style */

.mt5 {
    margin-top: 5px;
}

.mt10 {
    margin-top: 10px;
}

.mt20 {
    margin-top: 20px;
}

.table-row-selected {
    background-color: rgb(0, 188, 212);
}

.mf-select-table-container {
    max-height: 80vh;
    overflow: scroll;
}

#matieres {
    min-height: 200px;
}

.form-control {
    outline: 0;
    border-color: #ECECEC;
    border-style: solid;
    border-width: 1px;
    width: 95%;
    background-color: #ffffff;
    padding: 6px;
    border-radius: 2px;
    margin-bottom: 5px;
    font-size: 14px;
}

.angucomplete-holder {
    position: relative;
}

.angucomplete-dropdown {
    border-color: #ececec;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    width: 250px;
    padding: 6px;
    cursor: pointer;
    z-index: 9999;
    position: absolute;
    /*top: 32px;
    left: 0px;
    */
    margin-top: -6px;
    background-color: #ffffff;
}

.angucomplete-searching {
    color: #acacac;
    font-size: 14px;
}

.angucomplete-description {
    font-size: 14px;
}

.angucomplete-row {
    padding: 5px;
    color: #000000;
    margin-bottom: 4px;

}

.angucomplete-selected-row, .angucomplete-row:hover {
    background-color: lightblue;
    color: #ffffff;
}

.angucomplete-image-holder {
    padding-top: 2px;
    float: left;
    margin-right: 10px;
    margin-left: 5px;
}

.angucomplete-image {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    border-color: #ececec;
    border-style: solid;
    border-width: 1px;
}

.angucomplete-image-default {
    /* Add your own default image here
     background-image: url('/assets/default.png');
    */
    background-position: center;
    background-size: contain;
    height: 34px;
    width: 34px;
}

@charset "UTF-8";
.bootstrap-switch .bootstrap-switch-container:focus,
.bootstrap-switch:focus,
.compose-container .ta-text.ta-editor div:focus {
    outline: 0
}

.lists-widget ol.blur,
.lists-widget ol.blur ol {
    counter-reset: section
}

.alert {
    color: #fff;
    font-size: 13px;
    font-weight: 300
}

.alert .alert-link,
.alert strong,
.chartist h5 {
    font-weight: 400
}

.alert .close {
    margin-top: -3px
}

.alert .control-alert {
    padding-top: 10px
}

.alert .control-alert button {
    margin-right: 10px
}

.closeable button {
    color: #cbcbcb
}

.bg-success {
    background-color: rgba(144, 185, 0, .85);
    color: #fff
}

.bg-success a {
    color: #c54949
}

.bg-success a:hover {
    color: #e85656
}

.bg-info {
    background-color: rgba(45, 172, 209, .85);
    color: #fff
}

.bg-info a {
    color: #e9cd60
}

.bg-info a:hover {
    color: #dfb81c
}

.bg-warning {
    background-color: rgba(223, 184, 28, .85);
    color: #fff
}

.bg-warning a {
    color: #c54949
}

.bg-warning a:hover {
    color: #e85656
}

.bg-danger {
    background-color: rgba(232, 86, 86, .85);
    color: #fff
}

.bg-danger a {
    color: #e9cd60
}

.bg-danger a:hover {
    color: #dfb81c
}

.basic-btns {
    padding-top: 8px;
    margin-bottom: -8px
}

.basic-btns h5 {
    line-height: 35px;
    font-size: 12px
}

.basic-btns h5.row-sm {
    line-height: 30px
}

.basic-btns h5.row-xs {
    line-height: 22px
}

.basic-btns>.row {
    padding-bottom: 4px
}

.btns-row>div {
    margin-bottom: 12px
}

.btns-same-width-sm .btn {
    width: 48px
}

.btns-same-width-md .btn {
    width: 79px
}

.btns-same-width-lg .btn {
    width: 112px
}

ul.btn-list {
    margin: 0 0 0 -18px;
    padding: 6px 0 0;
    clear: both
}

.btn-icon,
.pie-chart-panel {
    padding: 0
}

ul.btn-list li {
    margin: 0 0 12px 18px;
    padding: 0;
    list-style: none;
    float: left
}

.btn-group-example,
.btn-group-wrapper {
    margin-bottom: 12px
}

.btn-icon {
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center
}

.btn-group-example {
    float: left;
    margin-right: 30px
}

.btn-toolbar-example {
    float: left
}

.progress-buttons-container {
    text-align: center;
    font-size: 16px
}

.progress-buttons-container span.button-title {
    display: inline-block;
    width: 100%;
    line-height: 1;
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 10px
}

.progress-buttons-container .row+.row {
    margin-top: 30px
}

.button-panel {
    height: 315px
}

.button-panel .btn {
    width: 150px
}

.large-buttons-panel {
    height: 202px
}

.button-panel.df-size-button-panel .btn-xs {
    width: 60px
}

.button-panel.df-size-button-panel .btn-sm {
    width: 90px
}

.button-panel.df-size-button-panel .btn-mm {
    width: 120px
}

.button-panel.df-size-button-panel .btn-md {
    width: 150px
}

.button-panel.df-size-button-panel .btn-xm {
    width: 175px
}

.button-panel.df-size-button-panel .btn-lg {
    width: 200px
}

.button-wrapper {
    text-align: center;
    margin: 5px 0
}

.admin-chart {
    width: 100%;
    height: 500px;
    font-size: 11px
}

.amcharts-export-menu-top-right {
    top: 10px;
    right: 0
}

#pieChart {
    max-width: 1120px
}

.amcharts-pie-slice {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
    cursor: pointer;
    box-shadow: 0 0 30px 0 #000
}

.amcharts-pie-slice:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-filter: url(#shadow);
    filter: url(#shadow)
}

.amChartsButtonSelected {
    background-color: #C00;
    border: 1px solid #C00;
    color: #666;
    border-radius: 5px;
    margin: 1px
}

.amChartsButton {
    background-color: #EEE;
    border: 1px solid #CCC;
    color: #000;
    border-radius: 5px;
    margin: 1px
}

.ct-area {
    fill-opacity: .5
}

.ct-label {
    color: #666;
    opacity: .9;
    fill: #666
}

.ct-chart .ct-label {
    font-size: 1em
}

.ct-chart svg {
    width: 100%;
    display: block
}

.contact-info,
.contact-info.phone-email .ion-email,
.labels-container .label-item,
.little-human .date,
.mail-panel .tag,
.mail-tag.tag.label,
.person-info .name {
    display: inline-block
}

.ct-series-a .ct-bar,
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut,
.ct-series-a .ct-slice-pie {
    stroke: #e65100
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
    fill: #e65100
}

.ct-series-b .ct-bar,
.ct-series-b .ct-line,
.ct-series-b .ct-point,
.ct-series-b .ct-slice-donut,
.ct-series-b .ct-slice-pie {
    stroke: #90b900
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
    fill: #90b900
}

.ct-series-c .ct-bar,
.ct-series-c .ct-line,
.ct-series-c .ct-point,
.ct-series-c .ct-slice-donut,
.ct-series-c .ct-slice-pie {
    stroke: #e85656
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
    fill: #e85656
}

.ct-series-d .ct-bar,
.ct-series-d .ct-line,
.ct-series-d .ct-point,
.ct-series-d .ct-slice-donut,
.ct-series-d .ct-slice-pie {
    stroke: #dfb81c
}

.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
    fill: #dfb81c
}

.ct-series-e .ct-bar,
.ct-series-e .ct-line,
.ct-series-e .ct-point,
.ct-series-e .ct-slice-donut,
.ct-series-e .ct-slice-pie {
    stroke: #2dacd1
}

.ct-series-e .ct-area,
.ct-series-e .ct-slice-pie {
    fill: #2dacd1
}

@media screen and (min-width:992px) {
    .row.morris-up>div {
        margin-top: -434px
    }
}

.area-morris-header {
    margin-top: 20px
}

.stacked-bar .ct-bar {
    stroke-width: 30px
}

.amChartsCompareList {
    border: 1px solid #CCC
}

.chart-panel {
    height: 495px
}

#filterChart {
    width: 96%;
    font-size: 11px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px
}

.amcharts-graph-g1 .amcharts-graph-fill,
.amcharts-graph-g2 .amcharts-graph-fill {
    -webkit-filter: url(#blur);
    filter: url(#blur)
}

.amcharts-cursor-fill {
    -webkit-filter: url(#shadow);
    filter: url(#shadow)
}

.chartjs-canvas-holder-first-row {
    height: 300px
}

.chartjs-canvas-holder-second-row {
    height: 350px
}

.chartjs-canvas-holder-third-row {
    height: 250px
}

@media screen and (min-width:1620px) {
    .row.shift-up>div {
        margin-top: -573px
    }
}

@media screen and (max-width:1620px) {
    .panel.feed-panel.large-panel {
        height: 824px
    }
}

.user-stats-panel .panel-title {
    padding: 0 0 15px
}

.blurCalendar {
    height: 475px
}

.letter-layout {
    margin-top: -15px;
    margin-right: -22px
}

.mail-panel.panel>.panel-body {
    padding-left: 0
}

.mail-navigation-container {
    float: left;
    position: relative;
    height: 550px;
    -webkit-transition: width .5s;
    transition: width .5s;
    color: #666;
    width: 200px;
    overflow: hidden;
    padding: 0
}

.mail-navigation-container .mail-navigation {
    cursor: pointer;
    font-weight: 300;
    font-size: 16px;
    text-align: left;
    padding: 10px 10px 10px 30px;
    margin-left: 0;
    -webkit-transition: .8s padding ease;
    transition: .8s padding ease
}

.mail-navigation-container .mail-navigation .new-mails {
    position: absolute;
    left: 150px;
    padding: 1px 6px;
    border: 1px solid;
    background-color: transparent;
    margin-top: -2px;
    font-size: 12px;
    margin-right: 5px;
    border-radius: 10px;
    -webkit-transition: .8s left ease;
    transition: .8s left ease
}

.mail-navigation-container .mail-navigation.active {
    background: rgba(0, 0, 0, .2);
    color: #fff;
    -webkit-transition: background-color .5s ease;
    transition: background-color .5s ease
}

.mail-navigation-container .mail-navigation.active:hover {
    background-color: rgba(0, 0, 0, .3)
}

.mail-navigation-container .mail-navigation.active .new-mails {
    background-color: transparent
}

.mail-navigation-container .mail-navigation:hover {
    background-color: rgba(0, 0, 0, .1)
}

.mail-navigation-container .btn.compose-button {
    width: 140px;
    font-weight: 300;
    border: 2px solid #d6d6d6;
    background-color: transparent;
    margin: 15px 0
}

.labels {
    margin-top: 14px
}

.labels-container {
    margin-top: 16px;
    text-align: center;
    padding-right: 28px;
    padding-left: 20px
}

.labels-title {
    padding-left: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, .12)
}

.labels-title .label-header {
    line-height: 24px
}

.label-item {
    margin: 5px
}

.add-label-container {
    margin-top: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 300
}

.add-label-container .label-input-stub {
    font-size: 14px;
    margin-left: 5px
}

.mail-messages-control .refresh-button,
.margin-left {
    margin-left: 10px
}

.add-label-container i {
    cursor: pointer
}

.mail-messages-control {
    padding: 10px
}

.mail-messages-control ul.dropdown-menu {
    margin-top: 5px
}

.mail-messages-control .btn {
    background-color: transparent;
    width: auto
}

.mail-messages-control .btn:active,
.mail-messages-control .btn:focus,
.mail-messages-control .btn:hover {
    color: #666;
    opacity: .8;
    box-shadow: none
}

.mail-messages-control .more-button {
    width: 60px;
    font-size: 14px;
    padding: 6px 5px
}

.mail-messages-control span.select-all-label {
    font-size: 13px;
    font-weight: 300
}

.message-container,
.side-message-navigation {
    float: left;
    padding: 0 15px;
    position: relative;
    height: 550px;
    -webkit-transition: width .5s;
    transition: width .5s;
    width: calc(100% - 200px);
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-left: 1px solid rgba(0, 0, 0, .12);
    box-shadow: -1px 0 0 0 rgba(255, 255, 255, .12)
}

.side-message-navigation {
    padding: 0;
    width: calc(100% - 200px)
}

.side-message-navigation .side-message-navigation-item {
    border-bottom: 1px solid rgba(255, 255, 255, .2)
}

.mail-body-part {
    width: calc(100% - 30px);
    text-overflow: ellipsis;
    height: 16px;
    overflow: hidden;
    margin-top: 3px;
    margin-left: 5px;
    opacity: .8
}

.mail-tag.tag.label {
    font-size: 14px;
    text-transform: uppercase;
    width: 65px
}

.phone-email i {
    color: #666
}

.message-container {
    padding: 15px 15px 10px;
    overflow-x: hidden
}

.little-human {
    cursor: pointer;
    -webkit-transition: border-left 1.5s ease;
    transition: border-left 1.5s ease;
    font-weight: 300
}

.little-human .little-human-picture {
    width: 45px;
    height: 45px;
    border-radius: 23px;
    margin: 7px 0 7px 7px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.little-human .name {
    font-size: 14px;
    vertical-align: super;
    white-space: nowrap
}

.little-human .name-wrap {
    margin-left: 10px
}

.little-human .date {
    float: right;
    margin: 10px 10px 0 5px;
    font-size: 13px;
    white-space: nowrap
}

.little-human .tag {
    vertical-align: super
}

.little-human .subject {
    white-space: nowrap;
    margin: 0 30px;
    font-size: 16px
}

.little-human:hover {
    background-color: rgba(255, 255, 255, .1)
}

.little-human:hover .little-human-picture {
    -webkit-animation: rotateReturnAnimation .5s;
    animation: rotateReturnAnimation .5s
}

.back-button {
    margin-left: 45px
}

.name-container {
    margin-left: 10px
}

.mail-checkbox {
    margin-top: 5px;
    margin-left: 6px
}

.mail-checkbox .custom-checkbox,
.mail-checkbox label.custom-radio {
    margin-left: 5px
}

.mail-panel .tag {
    text-transform: lowercase;
    font-size: 11px;
    font-weight: 300;
    width: 45px;
    cursor: pointer
}

.more-text-widget .upper-text,
.panel-title,
h1.al-title {
    text-transform: uppercase
}

.mail-panel .tag.label {
    padding: .2em .5em;
    border-radius: 2px;
    line-height: 1.1
}

.mail-panel .tag.work {
    background-color: #e65100
}

.mail-panel .tag.study {
    background-color: #dfb81c
}

.mail-panel .tag.family {
    background-color: #90b900
}

.mail-panel .tag.friend {
    background-color: #e85656
}

.messages {
    overflow: auto
}

.messages table {
    width: 100%
}

@media screen and (min-width:1199px) {
    .photo-td {
        width: 55px
    }
    .check-td {
        width: 35px
    }
}

.important {
    color: #dfb81c
}

.message-control-icon {
    cursor: pointer;
    font-size: 16px;
    margin-right: 5px
}

.person-info {
    padding: 0 0 10px 45px;
    margin-top: 10px
}

.person-info .human-picture {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin-top: -65px
}

.person-info .name {
    margin-left: 10px;
    margin-top: 5px
}

.person-info .name h2 {
    font-weight: 300;
    margin-bottom: 0;
    font-size: 24px
}

.person-info .name .second-name {
    margin: 5px 0
}

.no-padding {
    padding: 0
}

.contact-info div {
    margin-top: 10px
}

.contact-info.phone-email .ion-iphone {
    font-size: 32px;
    width: 27px;
    display: inline-block
}

.contact-info.phone-email .phone {
    font-size: 19px;
    font-weight: 300;
    vertical-align: super
}

.contact-info.phone-email .ion-email {
    font-size: 24px;
    width: 27px
}

.contact-info.phone-email .email {
    font-size: 16px;
    font-weight: 300;
    vertical-align: super
}

.contact-info.position-address div {
    margin-top: 17px
}

.contact-info.position-address .position {
    font-size: 20px;
    font-weight: 300
}

.contact-info.position-address .address {
    font-size: 16px;
    font-weight: 300
}

.message-details {
    margin: 5px 0;
    padding: 5px 0 5px 30px
}

.message-details .subject {
    font-size: 20px;
    margin-right: 10px;
    font-weight: 300
}

.message-details .control-icons {
    float: right;
    font-size: 20px;
    margin-right: 40px
}

.message-details .control-icons i {
    cursor: pointer
}

.message-details .date {
    white-space: nowrap;
    font-size: 12px
}

.message-body {
    margin: 20px 0;
    padding-left: 30px;
    font-weight: 300;
    line-height: 18px
}

.message-body p {
    margin-bottom: 0
}

.attachment {
    margin: 5px 0;
    padding-left: 30px
}

.attachment .file-icon {
    font-size: 24px;
    cursor: pointer
}

.attachment .file-name {
    font-size: 14px;
    margin-left: 3px;
    position: relative;
    top: -3px;
    font-weight: 300
}

.attachment .file-links {
    font-size: 14px
}

.line {
    height: 1px;
    background-color: #cbcbcb;
    opacity: .3
}

.answer-button {
    margin-left: 20px;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    padding-left: 40px;
    padding-right: 40px;
    background-color: #F26798
}

.answer-button:focus,
.answer-button:hover {
    color: #fff
}

.answer-container {
    float: right;
    margin-top: 10px
}

.answer-container .btn {
    margin-top: 3px;
    background-color: #63bbb2;
    border: none;
    color: #fff;
    width: 100px;
    -webkit-transition: none;
    transition: none
}

.answer-container .btn:hover {
    -webkit-transform: none;
    transform: none;
    background-color: #e85656
}

@-webkit-keyframes rotateReturnAnimation {
    0%,
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    50% {
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg)
    }
}

@keyframes rotateReturnAnimation {
    0%,
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    50% {
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg)
    }
}

@media screen and (max-width:1199px) {
    .name-h {
        display: inline
    }
    .person-info .human-picture {
        margin-top: -35px
    }
    .person-info .name {
        margin-top: 20px
    }
    .person-info .mail-tag {
        margin-top: 10px
    }
    .second-name {
        margin-top: 0
    }
}

@media screen and (max-width:990px) {
    .person-info .human-picture {
        width: 150px;
        height: 150px;
        border-radius: 75px;
        margin: 5px auto;
        display: block
    }
    .attachment,
    .message-body,
    .message-details,
    .person-info {
        padding-left: 10px
    }
    .message-container {
        padding-left: 15px
    }
    .back-button,
    .contact-info {
        margin-left: 15px
    }
    .additional-info {
        display: none
    }
    .little-human .date {
        font-size: 10px
    }
    .margin-left {
        margin-left: 5px
    }
}

@media screen and (max-width:760px) {
    .mail-navigation-container {
        width: 175px
    }
    .mail-navigation-container .mail-navigation {
        padding-left: 19px
    }
    .mail-navigation-container .mail-navigation .new-mails {
        left: 137px
    }
    .message-container,
    .side-message-navigation {
        width: calc(100% - 175px)
    }
    .mail-body-part {
        display: none
    }
    .little-human .little-human-picture {
        width: 30px;
        height: 30px
    }
    .messages-control .more-button {
        width: 50px
    }
}

@media screen and (max-width:560px) {
    .mail-navigation-container {
        padding: 0;
        width: 0
    }
    .mail-navigation-container.expanded {
        width: 155px
    }
    .mail-navigation-container .mail-navigation {
        font-size: 14px;
        padding-left: 23px
    }
    .mail-navigation-container .mail-navigation .new-mails {
        left: 116px;
        padding: 0 5px;
        margin-top: 0;
        font-size: 12px
    }
    .mail-navigation-container .mail-navigation .labels {
        margin-left: 10px
    }
    .mail-navigation-container .compose-button {
        width: 110px
    }
    .mail-messages-control .custom-checkbox,
    .mail-messages-control label.custom-radio {
        margin-left: 5px;
        margin-top: -2px
    }
    .message-container,
    .side-message-navigation {
        width: calc(100% - 155px)
    }
    .message-container.expanded,
    .side-message-navigation.expanded {
        width: 100%;
        border: none;
        box-shadow: none
    }
    div.toggle-navigation-container {
        display: inline-block
    }
    .little-human .name {
        white-space: inherit
    }
    .little-human .little-human-picture {
        display: none
    }
    .add-label-container {
        padding-left: 0;
        font-size: 13px;
        font-weight: 300
    }
}

.compose-header {
    padding: 8px 3px 8px 10px;
    color: #fff;
    font-weight: 300;
    font-size: 13px;
    background-color: #6b6b6b
}

.header-controls {
    display: inline-block;
    float: right
}

.header-controls i {
    margin-right: 5px;
    font-size: 14px;
    cursor: pointer
}

.header-controls i:hover {
    color: #63bbb2
}

.compose-container .ta-scroll-window>.ta-bind {
    height: 290px;
    overflow-y: scroll
}

.modal-compose input.form-control.compose-input {
    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0
}

.compose-footer {
    padding: 2px 4px;
    background-color: #f5f5f5
}

.compose-footer .btn-send {
    background-color: #63bbb2;
    color: #fff;
    padding: 2px 10px;
    margin: 3px;
    font-weight: 300
}

.compose-footer .btn-default {
    background-color: transparent;
    border: none
}

.footer-controls {
    float: right;
    margin: 6px
}

.compose-footer-icon {
    font-size: 18px;
    margin-right: 5px;
    cursor: pointer;
    color: #7d7d7d
}

.compose-footer-icon:hover {
    color: #63bbb2
}

.compose-container {
    background-color: #f5f5f5
}

.compose-container .ta-text.ta-editor {
    background-color: #cbcbcb
}

.footer-control-first {
    border-right: 2px solid #949494;
    display: inline-block;
    padding-right: 9px
}

.toggle-navigation-container {
    display: none;
    vertical-align: middle;
    margin-left: -1px
}

.toggle-navigation-container.detail-page {
    margin-left: 10px
}

.collapse-navigation-link {
    font-size: 32px;
    color: #666
}

.collapse-navigation-link:visited {
    color: #666
}

.collapse-navigation-link:hover {
    color: #dfb81c;
    opacity: .8
}

.collapsed {
    width: 0
}

.modal .modal-dialog.modal-compose {
    max-width: 398px;
    position: fixed;
    bottom: 0;
    right: 0;
    max-height: 474px
}

.modal .modal-dialog.modal-compose .bootstrap-tagsinput input,
.modal .modal-dialog.modal-compose .form-control {
    color: #7d7d7d;
    border-radius: 0
}

.modal .modal-dialog.modal-compose .bootstrap-tagsinput input::-webkit-input-placeholder,
.modal .modal-dialog.modal-compose .form-control::-webkit-input-placeholder {
    color: #7d7d7d;
    opacity: 1
}

.modal .modal-dialog.modal-compose .bootstrap-tagsinput input:-moz-placeholder,
.modal .modal-dialog.modal-compose .form-control:-moz-placeholder {
    color: #7d7d7d;
    opacity: 1
}

.modal .modal-dialog.modal-compose .bootstrap-tagsinput input::-moz-placeholder,
.modal .modal-dialog.modal-compose .form-control::-moz-placeholder {
    color: #7d7d7d;
    opacity: 1
}

.modal .modal-dialog.modal-compose .bootstrap-tagsinput input:-ms-input-placeholder,
.modal .modal-dialog.modal-compose .form-control:-ms-input-placeholder {
    color: #7d7d7d;
    opacity: 1
}

.modal .modal-dialog.modal-compose .ta-toolbar .btn {
    border-radius: 0;
    color: #7d7d7d;
    border: 1px transparent
}

.modal .modal-dialog.modal-compose .ta-toolbar .btn.active {
    box-shadow: none;
    background-color: rgba(0, 0, 0, .12)
}

.modal .modal-dialog.modal-compose .form-group label,
.modal .modal-dialog.modal-compose .help-block,
.modal .modal-dialog.modal-compose .panel-heading>.dropdown .dropdown-toggle,
.modal .modal-dialog.modal-compose .panel-title,
.modal .modal-dialog.modal-compose .panel-title>.small,
.modal .modal-dialog.modal-compose .panel-title>.small>a,
.modal .modal-dialog.modal-compose .panel-title>a,
.modal .modal-dialog.modal-compose .panel-title>small,
.modal .modal-dialog.modal-compose .panel-title>small>a,
.modal .modal-dialog.modal-compose .pie-chart-item,
.modal .modal-dialog.modal-compose .popular-app-cost,
.modal .modal-dialog.modal-compose .popular-app-info,
.modal .modal-dialog.modal-compose .traffic-text span,
.modal .modal-dialog.modal-compose h1,
.modal .modal-dialog.modal-compose h2,
.modal .modal-dialog.modal-compose h3,
.modal .modal-dialog.modal-compose h4,
.modal .modal-dialog.modal-compose h5,
.modal .modal-dialog.modal-compose h6,
.modal .modal-dialog.modal-compose ol.blur span,
.modal .modal-dialog.modal-compose p,
.modal .modal-dialog.modal-compose ul.blur {
    color: #7d7d7d
}

.modal .modal-dialog.modal-compose .feed-message .message-time,
.modal .modal-dialog.modal-compose .text-muted {
    color: #4a4a4a
}

@media (max-width:640px) {
    .modal .modal-dialog.modal-compose {
        max-height: 600px
    }
}

.form-horizontal label {
    line-height: 34px;
    margin-bottom: 0;
    padding-top: 0!important
}

.form-group label {
    margin-bottom: 5px;
    color: #666;
    font-weight: 400;
    font-size: 13px
}

.form-control {
    color: #666;
    border: 1px solid #cbcbcb;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: none
}

.form-control::-webkit-input-placeholder {
    color: #666;
    opacity: .7
}

.form-control:-moz-placeholder {
    color: #666;
    opacity: .7
}

.form-control::-moz-placeholder {
    color: #666;
    opacity: .7
}

.form-control:-ms-input-placeholder {
    color: #666;
    opacity: .7
}

.form-control:focus {
    box-shadow: none;
    border-color: #4db1a7;
    background: #fff
}

select.form-control {
    padding-left: 8px
}

textarea.form-control {
    height: 96px
}

.form-inline .form-group input {
    width: 100%
}

.form-inline .form-group label {
    margin-right: 12px
}

.form-inline button[type=submit] {
    margin-left: 12px
}

.form-inline label.custom-checkbox>span,
.form-inline label.custom-radio>span {
    display: block;
    margin-top: -13px;
    margin-right: 10px
}

.switcher-container {
    margin-right: 10px;
    font-weight: 400
}

.switcher-container input {
    display: none
}

.switcher-container .switcher {
    position: relative;
    display: inline-block;
    width: 84px;
    overflow: hidden;
    border-radius: 6px;
    box-sizing: border-box;
    -webkit-transform: translateZ(0)
}

.switcher-container .switcher.primary {
    border: 1px solid #e65100
}

.switcher-container .switcher.primary .handle.handle-on {
    background-color: #e65100
}

.switcher-container .switcher.success {
    border: 1px solid #90b900
}

.switcher-container .switcher.success .handle.handle-on {
    background-color: #90b900
}

.switcher-container .switcher.warning {
    border: 1px solid #dfb81c
}

.switcher-container .switcher.warning .handle.handle-on {
    background-color: #dfb81c
}

.switcher-container .switcher.danger {
    border: 1px solid #e85656
}

.switcher-container .switcher.danger .handle.handle-on {
    background-color: #e85656
}

.switcher-container .switcher.info {
    border: 1px solid #63bbb2
}

.switcher-container .switcher.info .handle.handle-on {
    background-color: #63bbb2
}

.switcher-container .switcher input {
    display: none
}

.switcher-container .switcher .handle-container {
    position: relative;
    width: 126px;
    cursor: pointer;
    -webkit-transform: translate3d(-42px, 0, 0);
    transform: translate3d(-42px, 0, 0);
    -webkit-transition: -webkit-transform .2s linear;
    transition: -webkit-transform .2s linear;
    transition: transform .2s linear;
    transition: transform .2s linear, -webkit-transform .2s linear
}

.switcher-container .switcher .handle-container .handle {
    width: 42px;
    float: left;
    line-height: 28px;
    height: 28px;
    font-size: 12px;
    text-align: center;
    color: #fff
}

.switcher-container .switcher .handle-container .handle.handle-off {
    background: #d6d6d6;
    color: #000
}

.switcher-container input:checked+.switcher {
    border: 1px solid #d6d6d6
}

.switcher-container input:checked+.switcher .handle-container {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.switch-container {
    display: inline-block
}

.switch-container.primary .bootstrap-switch.bootstrap-switch-on {
    border-color: #e65100
}

.switch-container.success .bootstrap-switch.bootstrap-switch-on {
    border-color: #90b900
}

.switch-container.warning .bootstrap-switch.bootstrap-switch-on {
    border-color: #dfb81c
}

.switch-container.danger .bootstrap-switch.bootstrap-switch-on {
    border-color: #e85656
}

.switch-container.info .bootstrap-switch.bootstrap-switch-on {
    border-color: #63bbb2
}

.bootstrap-switch {
    border-radius: 5px;
    border: 1px solid #fff;
    -webkit-transition: border-color ease-in-out .7s, box-shadow ease-in-out .7s;
    transition: border-color ease-in-out .7s, box-shadow ease-in-out .7s
}

.bootstrap-switch.bootstrap-switch-focused.bootstrap-switch-off,
.bootstrap-switch.bootstrap-switch-off {
    border-color: #d6d6d6
}

.bootstrap-switch .bootstrap-switch-container,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-handle-on,
.input-group>span {
    border-radius: 0
}

.bootstrap-switch.bootstrap-switch-focused {
    box-shadow: none
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
    background: #fff
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success {
    background: #90b900
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
    background: #e65100
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning {
    background: #dfb81c
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger {
    background: #e85656
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info {
    background: #63bbb2
}

.bootstrap-switch .bootstrap-switch-label {
    background: 0 0
}

.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
    -webkit-transition: margin-left .2s;
    transition: margin-left .2s
}

.switches {
    margin-left: -12px;
    margin-bottom: -12px
}

.switches .switch-container {
    float: left;
    margin-left: 12px;
    margin-bottom: 12px
}

.input-group {
    width: 100%;
    margin-bottom: 15px
}

label.custom-checkbox,
label.custom-radio {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0
}

label.custom-checkbox>input,
label.custom-radio>input {
    height: 0;
    z-index: -100!important;
    opacity: 0;
    position: absolute
}

label.custom-checkbox>input:checked+span:before,
label.custom-radio>input:checked+span:before {
    content: "\f00c";
    font-weight: 300
}

label.custom-checkbox>input:disabled+span,
label.custom-radio>input:disabled+span {
    color: #ddd;
    cursor: not-allowed
}

label.custom-checkbox>input:disabled+span:before,
label.custom-radio>input:disabled+span:before {
    border-color: #ddd!important;
    cursor: not-allowed
}

label.custom-checkbox>span,
label.custom-radio>span {
    position: relative;
    display: inline-block;
    margin: 0;
    line-height: 16px;
    font-weight: 300;
    cursor: pointer;
    padding-left: 22px;
    width: 100%
}

label.custom-checkbox>span:before,
label.custom-radio>span:before {
    cursor: pointer;
    font-family: fontAwesome;
    font-weight: 300;
    font-size: 12px;
    color: #666;
    content: "\a0";
    background-color: transparent;
    border: 1px solid #d6d6d6;
    border-radius: 0;
    display: inline-block;
    text-align: center;
    height: 16px;
    line-height: 14px;
    min-width: 16px;
    margin-right: 6px;
    position: relative;
    top: 0;
    margin-left: -22px;
    float: left
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: Roboto, sans-serif
}

label.custom-checkbox>span:hover:before,
label.custom-radio>span:hover:before {
    border-color: #4db1a7
}

.nowrap {
    white-space: nowrap
}

.cut-with-dots {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block
}

label.custom-radio>input:checked+span:before {
    content: "\f111"
}

label.custom-radio>span:before {
    border-radius: 16px;
    font-size: 9px
}

label.custom-input-primary>span:before {
    color: #e65100
}

label.custom-input-primary>span:hover:before {
    border-color: #e65100
}

label.custom-input-success>span:before {
    color: #90b900
}

label.custom-input-success>span:hover:before {
    border-color: #90b900
}

label.custom-input-warning>span:before {
    color: #dfb81c
}

label.custom-input-warning>span:hover:before {
    border-color: #dfb81c
}

label.custom-input-danger>span:before {
    color: #e85656
}

label.custom-input-danger>span:hover:before {
    border-color: #e85656
}

.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
    padding-top: 0
}

.input-demo {
    line-height: 25px
}

.has-success .control-label {
    color: #666
}

.has-success .form-control-feedback,
.has-success label.custom-checkbox,
.has-success label.custom-checkbox>span:before,
.has-success label.custom-radio,
.has-success label.custom-radio>span:before {
    color: #a6c733
}

.has-success .form-control {
    border: 1px solid #a6c733
}

.has-success .form-control:focus {
    box-shadow: none;
    border-color: #90b900
}

.has-success label.custom-checkbox>span:hover:before,
.has-success label.custom-radio>span:hover:before {
    border-color: #a6c733
}

.has-success .input-group-addon {
    background-color: #a6c733;
    color: #fff
}

.has-warning .control-label {
    color: #666
}

.has-warning .form-control-feedback,
.has-warning label.custom-checkbox,
.has-warning label.custom-checkbox>span:before,
.has-warning label.custom-radio,
.has-warning label.custom-radio>span:before {
    color: #e5c649
}

.has-warning .form-control {
    border: 1px solid #e5c649
}

.has-warning .form-control:focus {
    box-shadow: none;
    border-color: #dfb81c
}

.has-warning label.custom-checkbox>span:hover:before,
.has-warning label.custom-radio>span:hover:before {
    border-color: #e5c649
}

.has-warning .input-group-addon {
    background-color: #e5c649;
    color: #fff
}

.has-error .control-label {
    color: #666
}

.has-error .form-control-feedback,
.has-error label.custom-checkbox,
.has-error label.custom-checkbox>span:before,
.has-error label.custom-radio,
.has-error label.custom-radio>span:before {
    color: #ed7878
}

.has-error .form-control {
    border: 1px solid #ed7878
}

.has-error .form-control:focus {
    box-shadow: none;
    border-color: #e85656
}

.has-error label.custom-checkbox>span:hover:before,
.has-error label.custom-radio>span:hover:before {
    border-color: #ed7878
}

.has-error .input-group-addon {
    background-color: #ed7878;
    color: #fff
}

.has-feedback label~.form-control-feedback {
    top: 21px;
    font-size: 18px
}

.bootstrap-select .btn-default:focus {
    color: #666
}

.bootstrap-select>.dropdown-toggle.btn-danger,
.bootstrap-select>.dropdown-toggle.btn-danger:focus,
.bootstrap-select>.dropdown-toggle.btn-danger:hover,
.bootstrap-select>.dropdown-toggle.btn-info,
.bootstrap-select>.dropdown-toggle.btn-info:focus,
.bootstrap-select>.dropdown-toggle.btn-info:hover,
.bootstrap-select>.dropdown-toggle.btn-inverse,
.bootstrap-select>.dropdown-toggle.btn-inverse:focus,
.bootstrap-select>.dropdown-toggle.btn-inverse:hover,
.bootstrap-select>.dropdown-toggle.btn-primary,
.bootstrap-select>.dropdown-toggle.btn-primary:focus,
.bootstrap-select>.dropdown-toggle.btn-primary:hover,
.bootstrap-select>.dropdown-toggle.btn-success,
.bootstrap-select>.dropdown-toggle.btn-success:focus,
.bootstrap-select>.dropdown-toggle.btn-success:hover,
.bootstrap-select>.dropdown-toggle.btn-warning,
.bootstrap-select>.dropdown-toggle.btn-warning:focus,
.bootstrap-select>.dropdown-toggle.btn-warning:hover {
    color: #fff
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #fff;
    color: #ddd;
    border-color: #e2e2e2
}

.form-control[disabled]::-webkit-input-placeholder,
.form-control[readonly]::-webkit-input-placeholder,
fieldset[disabled] .form-control::-webkit-input-placeholder {
    color: #666;
    opacity: .5
}

.form-control[disabled]:-moz-placeholder,
.form-control[readonly]:-moz-placeholder,
fieldset[disabled] .form-control:-moz-placeholder {
    color: #666;
    opacity: .5
}

.form-control[disabled]::-moz-placeholder,
.form-control[readonly]::-moz-placeholder,
fieldset[disabled] .form-control::-moz-placeholder {
    color: #666;
    opacity: .5
}

.form-control[disabled]:-ms-input-placeholder,
.form-control[readonly]:-ms-input-placeholder,
fieldset[disabled] .form-control:-ms-input-placeholder {
    color: #666;
    opacity: .5
}

.form-control-rounded {
    border-radius: 16px
}

.help-block {
    color: #949494
}

.help-block.error-block {
    display: none
}

.has-error .help-block.error-block.basic-block {
    display: block
}

.input-group-addon-danger {
    background: #e85656;
    color: #fff;
    border-color: #e85656
}

.input-group-addon-warning {
    background: #dfb81c;
    color: #fff;
    border-color: #dfb81c
}

.input-group-addon-success {
    background: #90b900;
    color: #fff;
    border-color: #90b900
}

.input-group-addon-primary {
    background: #e65100;
    color: #fff;
    border-color: #e65100
}

.checkbox-demo-row {
    margin-bottom: 12px
}

.dropdown-menu {
    border-radius: 5px
}

.bootstrap-select.btn-group button.btn.btn-default {
    background: 0 0;
    color: #666
}

.bootstrap-select.btn-group button.btn.btn-default:hover {
    background: #fff;
    box-shadow: none;
    outline: 0!important
}

.bootstrap-select.btn-group button.btn.btn-default:active {
    background: #fff;
    box-shadow: none
}

.bootstrap-select.btn-group.open>.btn.btn-default.dropdown-toggle {
    background: #fff;
    box-shadow: none;
    border-color: #d6d6d6
}

.bootstrap-select.btn-group.open>.btn {
    border-radius: 5px 5px 0 0
}

.bootstrap-select.btn-group.open .dropdown-menu.open {
    border: 1px solid #dcdcdc;
    border-top: none;
    border-radius: 0 0 5px 5px
}

.bootstrap-select.btn-group.with-search.open .btn-default+.dropdown-menu .bs-searchbox .form-control,
.bootstrap-tagsinput {
    border: 1px solid #cbcbcb;
    background-color: #fff
}

.bootstrap-select.btn-group .notify,
.bootstrap-select.btn-group.with-search.open .btn-default+.dropdown-menu .no-results {
    color: #7d7d7d
}

.bootstrap-tagsinput {
    color: #666;
    border-radius: 5px;
    box-shadow: none;
    max-width: 100%;
    font-size: 14px;
    line-height: 26px;
    width: 100%
}

.bootstrap-tagsinput.form-control {
    display: block;
    width: 100%
}

.bootstrap-tagsinput .tag {
    border-radius: 3px;
    font-weight: 400;
    font-size: 11px;
    padding: 4px 8px
}

.bootstrap-tagsinput .tag [data-role=remove]:hover {
    box-shadow: none
}

.bootstrap-tagsinput input {
    background-color: #fff;
    border: 1px solid #cbcbcb;
    border-radius: 5px;
    line-height: 22px;
    font-size: 11px;
    min-width: 53px
}

.bootstrap-tagsinput input::-webkit-input-placeholder {
    color: #666;
    opacity: .8
}

.bootstrap-tagsinput input:-moz-placeholder {
    color: #666;
    opacity: .8
}

.bootstrap-tagsinput input::-moz-placeholder {
    color: #666;
    opacity: .8
}

.bootstrap-tagsinput input:-ms-input-placeholder {
    color: #666;
    opacity: .8
}

.ui-select-multiple.ui-select-bootstrap {
    min-height: 34px;
    padding: 4px 3px 0 12px
}

.progress {
    background: rgba(0, 0, 0, .07)
}

.progress-bar-primary {
    background-color: #e65100
}

.progress-bar-success {
    background-color: #b1ce4d
}

.progress-bar-warning {
    background-color: #dfb81c
}

.progress-bar-danger {
    background-color: #e85656
}

.has-success .input-group-addon {
    border: none
}

.input-group>span.addon-left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px
}

.input-group>span.addon-right {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px
}

.with-primary-addon:focus {
    border-color: #e65100
}

.with-warning-addon:focus {
    border-color: #dfb81c
}

.with-success-addon:focus {
    border-color: #90b900
}

.with-danger-addon:focus {
    border-color: #e85656
}

.sub-little-text {
    font-size: 12px
}

.show-grid div[class^=col-] {
    padding: 10px;
    box-sizing: border-box
}

.show-grid div[class^=col-] div {
    color: #666;
    text-align: center;
    font-size: 18px;
    background-color: rgba(102, 102, 102, .3);
    padding: 12px 5px
}

.grid-h {
    margin-top: 40px;
    margin-bottom: 0
}

.grid-h:first-child {
    margin-top: 0
}

.icons-list>div {
    text-align: center;
    margin-bottom: 32px
}

.icons-list i {
    font-weight: 400;
    font-size: 18px;
    cursor: pointer
}

.icons-list.primary i:hover {
    color: #e65100
}

.icons-list.success i:hover {
    color: #90b900
}

.icons-list.warning i:hover {
    color: #dfb81c
}

.icons-list.danger i:hover {
    color: #e85656
}

a.see-all-icons {
    float: right
}

.awesomeIcons {
    height: 308px
}

.kameleon-row {
    display: inline-block;
    min-width: 102px;
    width: 20%
}

.kameleon-row .kameleon-icon {
    padding: 0 10px
}

.kameleon-row .kameleon-icon img {
    width: 81px
}

@media (max-width:750px) {
    .kameleon-row {
        width: 25%
    }
}

@media (max-width:550px) {
    .kameleon-row {
        width: 33%
    }
}

@media (max-width:430px) {
    .kameleon-row {
        width: 50%
    }
}

.kameleon-icon-tabs {
    max-width: 84px
}

.kameleon-icon-tabs img {
    width: 100%;
    min-width: 81px;
    min-height: 81px
}

.kameleon-icon {
    text-align: center;
    margin: 0 auto
}

.kameleon-icon img {
    width: 100%
}

.kameleon-icon span {
    display: block;
    text-align: center;
    white-space: nowrap
}

.with-round-bg {
    margin-bottom: 6px
}

.with-round-bg img {
    border-radius: 50%;
    margin-bottom: 4px;
    background: #fff
}

.with-round-bg.success img {
    background: #90b900
}

.with-round-bg.danger img {
    background: #e85656
}

.with-round-bg.warning img {
    background: #dfb81c
}

.with-round-bg.info img {
    background: #2dacd1
}

.with-round-bg.primary img {
    background: #e65100
}

.toast {
    background-color: #e65100
}

.toast-success {
    background-color: #90b900
}

.toast-error {
    background-color: #e85656
}

.toast-info {
    background-color: #2dacd1
}

.toast-warning {
    background-color: #dfb81c
}

#toast-container.toast-bottom-full-width>div,
#toast-container.toast-top-full-width>div {
    margin: 5px auto
}

.modal-content {
    border-radius: 5px;
    border: none;
    color: #7d7d7d
}

.modal-header {
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px
}

.modal-header .modal-title {
    color: #7d7d7d
}

.modal-buttons {
    margin-top: -12px;
    margin-right: -22px
}

.modal-buttons button {
    float: left;
    margin-right: 24px;
    margin-top: 12px
}

.modal-buttons.same-width button {
    width: 160px;
    text-align: center
}

.modal {
    text-align: center
}

.modal .modal-dialog {
    display: inline-block;
    text-align: left;
    margin: 0 auto
}

.modal .modal-dialog .form-control {
    color: #6b6b6b;
    background-color: transparent;
    border: 1px solid #e7e7e7
}

.modal .modal-dialog .form-control::-webkit-input-placeholder {
    color: #7d7d7d;
    opacity: .9
}

.modal .modal-dialog .form-control:-moz-placeholder {
    color: #7d7d7d;
    opacity: .9
}

.modal .modal-dialog .form-control::-moz-placeholder {
    color: #7d7d7d;
    opacity: .9
}

.modal .modal-dialog .form-control:-ms-input-placeholder {
    color: #7d7d7d;
    opacity: .9
}

.close,
.close:active,
.close:focus,
.close:hover {
    opacity: 1
}

.modal .modal-dialog .modal-body div,
.modal .modal-dialog .modal-body p,
.modal .modal-dialog .modal-body span {
    color: #7d7d7d
}

.modal .modal-header {
    border: none
}

.modal .modal-header .close {
    margin-top: -5px
}

.modal .modal-footer {
    border: none;
    padding-top: 0
}

.modal .modal-icon {
    margin-right: 3px
}

.modal .sn-link-close {
    color: #7d7d7d;
    font-size: 30px
}

.modal .sn-link-close:hover {
    color: #e85656
}

@media screen and (min-width:768px) {
    .modal:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 100%
    }
}

.message-modal .modal-header {
    text-align: center
}

.message-modal .modal-header i {
    font-size: 32px
}

.message-modal .modal-title {
    text-align: center;
    font-size: 18px;
    margin-bottom: 12px;
    margin-top: 18px
}

.message-modal .modal-body {
    text-align: center;
    padding: 0;
    font-size: 14px
}

.message-modal .modal-footer {
    margin: 24px 0 12px;
    text-align: center
}

.control {
    margin-bottom: 10px
}

.control .form-control {
    width: 75%
}

.control span {
    white-space: pre-line
}

.notification-panel .control,
.notification-panel .radio,
.notification-panel label,
.notification-panel label.custom-checkbox>span,
.notification-panel label.custom-radio>span {
    font-weight: 300
}

.radio-controls .custom-radio {
    margin-top: 5px
}

@media (max-width:991px) {
    .toastr-radio-setup {
        margin-left: 22px
    }
}

.radio-header {
    margin-bottom: 0
}

.radio-header.position-header {
    margin-top: 15px
}

.button-row {
    line-height: 37px
}

.button-row button {
    width: 125px
}

.result-toastr {
    border-radius: 5px;
    color: rgba(255, 255, 255, .9);
    background-color: rgba(0, 0, 0, .33);
    border: none
}

.sub-label {
    margin-top: 5px
}

.toast-title {
    font-weight: 400
}

.toast-message {
    font-weight: 300
}

#toast-container.toast-bottom-center .toast,
#toast-container.toast-top-center .toast {
    margin-bottom: 5px
}

h3.with-line {
    border-bottom: 1px solid #d6d6d6;
    color: #666;
    font-weight: 400;
    padding-top: 30px;
    margin-bottom: 14px;
    line-height: 39px;
    width: 100%
}

.panel .panel-body h3.with-line {
    margin-bottom: 14px;
    margin-top: 0
}

.profile-block {
    background: #fff
}

.profile-block .nav-tabs>li>a {
    border-radius: 0;
    -webkit-transition: background, 0s, ease!important;
    transition: background, 0s, ease!important
}

.profile-block .progress {
    border-radius: 0;
    margin-bottom: 6px
}

.profile-block .progress-info {
    margin-bottom: 8px;
    margin-top: -2px
}

.profile-block .tab-content {
    padding: 15px 15px 25px
}

.profile-block .progress-bar-primary {
    background-color: #63bbb2
}

.profile-block .form-group {
    margin-bottom: 14px
}

.profile-block .form-group label {
    text-align: right
}

.profile-block .form-control {
    font-size: 13px
}

.notification .form-group label {
    text-align: left;
    padding-left: 26px
}

.userpic {
    border: 1px dashed #b8b8b8;
    width: 202px;
    height: 202px;
    position: relative;
    cursor: pointer
}

.userpic .userpic-wrapper {
    width: 200px;
    height: 200px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.userpic img {
    max-width: 100%;
    max-height: 100%
}

.userpic i {
    display: none;
    position: absolute;
    font-size: 32px;
    background: #fff;
    cursor: pointer;
    color: #e65100;
    top: -11px;
    right: -11px;
    height: 26px;
    border-radius: 50%
}

.userpic i:before {
    line-height: 26px
}

.userpic i:hover {
    color: #e85656
}

a.sn-link,
a.sn-link i.socicon,
a.sn-link.connected span,
a.sn-link:hover span {
    color: #666
}

.userpic a.change-userpic {
    display: none;
    width: 202px;
    background: rgba(0, 0, 0, .7);
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    color: #666;
    text-decoration: none;
    position: absolute;
    bottom: -1px;
    left: -1px;
    line-height: 32px;
    text-align: center
}

.userpic:hover .change-userpic,
.userpic:hover i,
a.sn-link,
a.sn-link.connected em {
    display: block
}

a.sn-link,
body a {
    text-decoration: none!important
}

.save-profile {
    margin: 23px 0 12px 12px
}

a.sn-link {
    -webkit-transition: none;
    transition: none;
    border-radius: 5px;
    background-color: transparent;
    padding: 8px;
    border: 1px solid transparent;
    margin-bottom: 23px;
    width: 100%;
    position: relative;
    cursor: pointer
}

a.sn-link i.socicon {
    padding: 6px;
    border-radius: 50%;
    width: auto;
    font-size: 17px;
    margin-right: 8px;
    background-color: rgba(0, 0, 0, .15)
}

a.sn-link.connected,
a.sn-link:hover {
    border-color: #e65100;
    border-width: 2px;
    padding: 7px
}

a.sn-link.connected i,
a.sn-link:hover i {
    background: #e65100
}

a.sn-link span {
    font-size: 14px;
    line-height: 29px
}

a.sn-link .socicon {
    background: 0 0
}

.close {
    box-shadow: none;
    outline: 0!important
}

.connected .sn-link-close {
    position: absolute;
    top: 1px;
    right: 0;
    width: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 32px;
    color: #e65100;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    outline: 0
}

.connected .sn-link-close:hover {
    color: #e85656;
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
}

.social-profiles {
    padding-top: 3px;
    margin-bottom: -3px;
    padding-left: 12px
}

.profile-page .form-group label {
    line-height: 34px;
    font-size: 14px;
    font-weight: 300
}

.profile-page .form-group input {
    font-weight: 300
}

.slider-box {
    min-height: 86px
}

.irs-bar,
.irs-bar-edge,
.irs-line-left,
.irs-line-mid,
.irs-line-right,
.irs-slider {
    background-image: url(../assets/img/theme/vendor/ionrangeslider/img/sprite-skin-flat.png);
    background-repeat: repeat-x
}

.table-panel {
    height: 295px
}

.tabset-group>div+div {
    margin-top: 15px
}

.panel.tabs-panel .panel-body {
    padding: 0
}

.panel.tabs-panel .dropdown-menu {
    min-width: 132px;
    top: auto;
    border: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px
}

.panel.tabs-panel.with-scroll .panel-body,
.xsmall-panel .nav-tabs,
.xsmall-panel .tab-content {
    height: 100%
}

.inline-icon {
    display: inline-block;
    margin: 20px 5px
}

.panel.horizontal-tabs .tab-content {
    height: calc(100% - 40px)
}

.tpl-skin-panel {
    width: 300px
}

.tpl-skin-panel .tpl-skin-option {
    padding: 10px;
    line-height: 83px;
    color: #666
}

.tpl-skin-panel .tpl-skin-option:hover {
    cursor: pointer;
    background-color: #e65100
}

.tpl-skin-panel .tpl-skin-option+.tpl-skin-option {
    border-top: 1px solid #dcdcdc
}

.tpl-skin-panel .skin-thumbnail {
    width: 100%
}

.tree-node {
    line-height: 25px;
    cursor: pointer
}

.tree-node.selected {
    background-color: #dcdcdc
}

.tree-node .control {
    cursor: pointer;
    font-size: 16px;
    padding-left: 5px
}

.tree-node:hover {
    background-color: #cbcbcb
}

.jstree-default a.jstree-clicked,
.jstree-default a.jstree-hovered {
    background-color: rgba(0, 0, 0, .25)
}

.jstree-default a.jstree-anchor:hover,
.jstree-default a.jstree-wholerow:hover {
    background-color: rgba(0, 0, 0, .15)
}

.control-side>div {
    margin-top: 5px;
    margin-bottom: 10px
}

.control-side>div .btn {
    width: 100px
}

#tree-root {
    border-left: 1px solid #dcdcdc;
    padding-left: 10px
}

.tree-panel {
    height: 500px
}

.search-container {
    margin-top: 10px
}

h1,
h2,
h3,
h4,
h5,
h6 {
    width: 100%;
    margin-top: 0
}

h1.color,
h2.color,
h3.color,
h4.color,
h5.color,
h6.color {
    color: #e85656
}

body a {
    color: #e65100;
    -webkit-transition: color .2s ease;
    transition: color .2s ease
}

.leaflet-bar a,
.leaflet-control-attribution a,
a.learn-more {
    text-decoration: none
}

body a:hover {
    color: #e65100
}

h1 {
    font-size: 32px
}

h2 {
    font-size: 24px
}

h3 {
    font-size: 20px
}

h4 {
    font-size: 18px
}

h5 {
    font-size: 15px
}

.typography-document-samples p {
    margin: 0
}

.typography-document-samples .typography-widget {
    height: 100%
}

.typography-document-samples .typography-widget .panel {
    height: 620px
}

.typography-document-samples .typography-widget .panel-title {
    text-align: center;
    width: 100%
}

.typography-document-samples .typography-widget .panel.with-scroll .panel-body {
    height: calc(100% - 45px)
}

.typography-document-samples .typography-widget .panel-content {
    padding: 15px 22px 5px
}

.heading-widget h1,
.heading-widget h2,
.heading-widget h3,
.heading-widget h4,
.heading-widget h5,
.heading-widget h6 {
    width: 100%;
    font-weight: 300;
    text-align: center
}

.heading-widget p {
    line-height: 16px;
    font-weight: 400;
    text-align: center
}

.more-text-widget {
    text-align: center;
    font-size: 14px
}

.more-text-widget p {
    line-height: 17px
}

.more-text-widget .gray {
    color: #767676
}

.more-text-widget .black {
    color: #585858
}

.more-text-widget .light-text {
    font-weight: 300
}

.more-text-widget .regular-text {
    font-weight: 400
}

.more-text-widget .bold-text {
    font-weight: 700
}

.more-text-widget .small-text {
    padding: 5px 0 0
}

.more-text-widget .small-text p {
    font-size: 9px;
    font-weight: 300;
    line-height: 10px
}

.color-widget,
.lists-widget {
    font-weight: 400
}

.color-widget {
    text-align: center;
    font-size: 14px
}

.color-widget p {
    line-height: 17px
}

.color-widget .section-block {
    margin: 14px 0
}

.color-widget .yellow-text p {
    color: #dfb81c
}

.color-widget .red-text p {
    color: #e85656
}

.color-widget .links h3 {
    margin-bottom: 10px
}

.color-widget .links p {
    margin-bottom: 0
}

.color-widget .links p.hovered a {
    color: #e65100
}

.lists-widget .list-header {
    width: 100%;
    text-align: center
}

.lists-widget .accent {
    margin-top: 30px;
    color: #e5c649;
    line-height: 14px;
    font-size: 14px;
    padding-left: 11px;
    border-left: 4px solid #e5c649;
    margin-left: 13px
}

.lists-widget ol.blur,
.lists-widget ul.blur {
    padding-left: 13px;
    margin-bottom: 19px;
    list-style: none;
    padding-top: 1px
}

.lists-widget ol.blur li,
.lists-widget ul.blur li {
    margin-top: 5px;
    font-size: 14px
}

.lists-widget ol.blur li ol,
.lists-widget ol.blur li ul,
.lists-widget ul.blur li ol,
.lists-widget ul.blur li ul {
    padding-left: 20px;
    margin-bottom: 0;
    list-style: none
}

.lists-widget ul.blur li:before {
    content: "• ";
    color: #e5c649;
    width: 10px;
    display: inline-block
}

.lists-widget ol.blur li {
    color: #e5c649;
    padding-left: 0;
    line-height: 14px;
    position: relative
}

.lists-widget ol.blur li span {
    color: #666;
    display: block
}

.lists-widget ol.blur li ol {
    padding-left: 0;
    margin-left: 12px
}

.lists-widget ol.blur li:before {
    content: counters(section, ".") ".";
    counter-increment: section;
    width: 19px;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.banner,
html {
    position: relative
}

.lists-widget ol.blur>li span {
    padding-left: 14px
}

.lists-widget ol.blur ol>li:before {
    width: 30px
}

.lists-widget ol.blur ol>li span {
    padding-left: 27px
}

.lists-widget ol.blur ol ol>li:before {
    width: 40px
}

.lists-widget ol.blur ol ol>li span {
    padding-left: 40px
}

.columns-section {
    background-color: #fff
}

p {
    margin-bottom: 12px;
    font-size: 14px
}

p.small-text {
    color: #949494;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px
}

.cols-three,
.cols-two {
    margin-bottom: 50px
}

.cols-two>div {
    float: left;
    width: 350px;
    margin-left: 40px
}

.cols-two>div:first-child {
    margin-left: 0
}

.cols-three>div {
    float: left;
    width: 222px;
    margin-left: 40px
}

.cols-three>div:first-child {
    margin-left: 0
}

a.learn-more {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px
}

.img-wrapper {
    margin-bottom: 19px;
    margin-top: 5px;
    overflow: hidden;
    height: 180px
}

.img-wrapper img {
    width: 100%
}

.cols-three p {
    margin-bottom: 10px
}

.banner {
    margin-bottom: 20px
}

.large-banner-wrapper {
    overflow: hidden;
    height: 400px
}

.large-banner-wrapper img {
    height: 100%;
    width: 100%;
    display: block
}

.banner-text-wrapper {
    margin-top: -400px;
    height: 400px;
    text-align: center
}

.banner-text {
    padding: 85px 90px 60px;
    display: inline-block;
    margin: 67px auto;
    min-width: 432px;
    overflow: hidden;
    background: rgba(0, 0, 0, .75)
}

.banner-text h1 {
    font-weight: 700;
    width: 100%;
    color: #fff;
    margin-bottom: 10px
}

.banner-text p {
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
    color: #e65100;
    margin-bottom: 0
}

@media (max-width:600px) {
    .banner-text {
        padding: 55px 60px 30px;
        min-width: 0
    }
    .banner-text h1 {
        font-size: 24px
    }
    .banner-text p {
        font-size: 16px
    }
}

@media (max-width:400px) {
    .banner-text {
        min-width: 0;
        width: 100%;
        height: 100%;
        margin: 0
    }
}

.photo-desc {
    margin-top: 12px;
    text-align: center
}

.text-info {
    width: 90%
}

.text-info p {
    margin-bottom: 10px
}

.section-block {
    padding-bottom: 12px
}

.separator {
    height: 1px;
    background: #dcdcdc;
    width: 100%;
    margin-bottom: 19px;
    margin-top: 16px
}

.section {
    padding: 0 20px 50px
}

.panel.banner-column-panel {
    padding: 0;
    margin-bottom: 90px
}

.panel.banner-column-panel .panel-body {
    padding: 0
}

@media screen and (min-width:1620px) {
    .col-xlg-1 {
        width: 8.33333333%
    }
    .col-xlg-2 {
        width: 16.66666667%
    }
    .col-xlg-3 {
        width: 25%
    }
    .col-xlg-4 {
        width: 33.33333333%
    }
    .col-xlg-5 {
        width: 41.66666667%
    }
    .col-xlg-6 {
        width: 50%
    }
    .col-xlg-7 {
        width: 58.33333333%
    }
    .col-xlg-8 {
        width: 66.66666667%
    }
    .col-xlg-9 {
        width: 75%
    }
    .col-xlg-10 {
        width: 83.33333333%
    }
    .col-xlg-11 {
        width: 91.66666667%
    }
    .col-xlg-12 {
        width: 100%
    }
}

.i-face,
.i-money,
.i-person,
.i-refresh {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
    height: 80px
}

.label {
    border-radius: 0
}

.label-primary {
    background: #e65100
}

.label-info {
    background: #63bbb2
}

.label-success {
    background: #90b900
}

.label-warning {
    background: #dfb81c
}

.label-danger {
    background: #e85656
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: 0
}

.bootstrap-select .dropdown-toggle:focus,
a,
a.al-logo {
    outline: 0!important
}

.btn {
    border-radius: 5px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease
}

button.progress-button.btn.btn-danger,
button.progress-button.btn.btn-default,
button.progress-button.btn.btn-info,
button.progress-button.btn.btn-primary,
button.progress-button.btn.btn-success,
button.progress-button.btn.btn-warning {
    border-radius: 0
}

.open>.btn.dropdown-toggle.btn.btn-primary {
    background: #e65100;
    border-color: #e65100
}

.open>.btn.dropdown-toggle.btn-success {
    background: #7a9d00;
    border-color: #7a9d00
}

.open>.btn.dropdown-toggle.btn-info {
    background: #2692b2;
    border-color: #2692b2
}

.open>.btn.dropdown-toggle.btn-warning {
    background: #be9c18;
    border-color: #be9c18
}

.open>.btn.dropdown-toggle.btn-danger {
    background: #c54949;
    border-color: #c54949
}

button.btn.btn-primary {
    background: #e65100;
    border-color: #e65100
}

button.btn.btn-primary.disabled,
button.btn.btn-primary.disabled.active,
button.btn.btn-primary.disabled.focus,
button.btn.btn-primary.disabled:active,
button.btn.btn-primary.disabled:focus,
button.btn.btn-primary.disabled:hover,
button.btn.btn-primary[disabled],
button.btn.btn-primary[disabled].active,
button.btn.btn-primary[disabled].focus,
button.btn.btn-primary[disabled]:active,
button.btn.btn-primary[disabled]:focus,
button.btn.btn-primary[disabled]:hover,
fieldset[disabled] button.btn.btn-primary,
fieldset[disabled] button.btn.btn-primary.active,
fieldset[disabled] button.btn.btn-primary.focus,
fieldset[disabled] button.btn.btn-primary:active,
fieldset[disabled] button.btn.btn-primary:focus,
fieldset[disabled] button.btn.btn-primary:hover {
    background: rgba(230, 81, 0, 0.37);
    border-color: #e4eae6;
}

button.btn.btn-primary.disabled.active:hover,
button.btn.btn-primary.disabled.focus:hover,
button.btn.btn-primary.disabled:active:hover,
button.btn.btn-primary.disabled:focus:hover,
button.btn.btn-primary.disabled:hover,
button.btn.btn-primary.disabled:hover:hover,
button.btn.btn-primary[disabled].active:hover,
button.btn.btn-primary[disabled].focus:hover,
button.btn.btn-primary[disabled]:active:hover,
button.btn.btn-primary[disabled]:focus:hover,
button.btn.btn-primary[disabled]:hover,
button.btn.btn-primary[disabled]:hover:hover,
fieldset[disabled] button.btn.btn-primary.active:hover,
fieldset[disabled] button.btn.btn-primary.focus:hover,
fieldset[disabled] button.btn.btn-primary:active:hover,
fieldset[disabled] button.btn.btn-primary:focus:hover,
fieldset[disabled] button.btn.btn-primary:hover,
fieldset[disabled] button.btn.btn-primary:hover:hover {
    -webkit-transform: none;
    transform: none
}

button.btn.btn-primary.active,
button.btn.btn-primary.focus,
button.btn.btn-primary:active,
button.btn.btn-primary:focus,
button.btn.btn-primary:hover {
    background: #e65100;
    border-color: #e65100
}

button.btn.btn-primary:active,
button.btn.btn-primary:target {
    background-color: #e65100
}

button.btn.btn-default {
    border-width: 1px;
    color: #666;
    background: 0 0;
    border-color: #d6d6d6
}

button.btn.btn-default.disabled,
button.btn.btn-default.disabled.active,
button.btn.btn-default.disabled.focus,
button.btn.btn-default.disabled:active,
button.btn.btn-default.disabled:focus,
button.btn.btn-default.disabled:hover,
button.btn.btn-default[disabled],
button.btn.btn-default[disabled].active,
button.btn.btn-default[disabled].focus,
button.btn.btn-default[disabled]:active,
button.btn.btn-default[disabled]:focus,
button.btn.btn-default[disabled]:hover,
fieldset[disabled] button.btn.btn-default,
fieldset[disabled] button.btn.btn-default.active,
fieldset[disabled] button.btn.btn-default.focus,
fieldset[disabled] button.btn.btn-default:active,
fieldset[disabled] button.btn.btn-default:focus,
fieldset[disabled] button.btn.btn-default:hover {
    background: 0 0;
    border-color: #e2e2e2
}

button.btn.btn-default.disabled.active:hover,
button.btn.btn-default.disabled.focus:hover,
button.btn.btn-default.disabled:active:hover,
button.btn.btn-default.disabled:focus:hover,
button.btn.btn-default.disabled:hover,
button.btn.btn-default.disabled:hover:hover,
button.btn.btn-default[disabled].active:hover,
button.btn.btn-default[disabled].focus:hover,
button.btn.btn-default[disabled]:active:hover,
button.btn.btn-default[disabled]:focus:hover,
button.btn.btn-default[disabled]:hover,
button.btn.btn-default[disabled]:hover:hover,
fieldset[disabled] button.btn.btn-default.active:hover,
fieldset[disabled] button.btn.btn-default.focus:hover,
fieldset[disabled] button.btn.btn-default:active:hover,
fieldset[disabled] button.btn.btn-default:focus:hover,
fieldset[disabled] button.btn.btn-default:hover,
fieldset[disabled] button.btn.btn-default:hover:hover {
    -webkit-transform: none;
    transform: none
}

button.btn.btn-default.active,
button.btn.btn-default.focus,
button.btn.btn-default:active,
button.btn.btn-default:focus,
button.btn.btn-default:hover {
    background: 0 0;
    border-color: #bebebe
}

button.btn.btn-default:active,
button.btn.btn-default:target {
    background-color: rgba(0, 0, 0, .2);
    color: #666
}

button.btn.btn-success {
    background: #90b900;
    border-color: #90b900
}

button.btn.btn-success.disabled,
button.btn.btn-success.disabled.active,
button.btn.btn-success.disabled.focus,
button.btn.btn-success.disabled:active,
button.btn.btn-success.disabled:focus,
button.btn.btn-success.disabled:hover,
button.btn.btn-success[disabled],
button.btn.btn-success[disabled].active,
button.btn.btn-success[disabled].focus,
button.btn.btn-success[disabled]:active,
button.btn.btn-success[disabled]:focus,
button.btn.btn-success[disabled]:hover,
fieldset[disabled] button.btn.btn-success,
fieldset[disabled] button.btn.btn-success.active,
fieldset[disabled] button.btn.btn-success.focus,
fieldset[disabled] button.btn.btn-success:active,
fieldset[disabled] button.btn.btn-success:focus,
fieldset[disabled] button.btn.btn-success:hover {
    background: #90b900;
    border-color: #9cc50c
}

button.btn.btn-success.disabled.active:hover,
button.btn.btn-success.disabled.focus:hover,
button.btn.btn-success.disabled:active:hover,
button.btn.btn-success.disabled:focus:hover,
button.btn.btn-success.disabled:hover,
button.btn.btn-success.disabled:hover:hover,
button.btn.btn-success[disabled].active:hover,
button.btn.btn-success[disabled].focus:hover,
button.btn.btn-success[disabled]:active:hover,
button.btn.btn-success[disabled]:focus:hover,
button.btn.btn-success[disabled]:hover,
button.btn.btn-success[disabled]:hover:hover,
fieldset[disabled] button.btn.btn-success.active:hover,
fieldset[disabled] button.btn.btn-success.focus:hover,
fieldset[disabled] button.btn.btn-success:active:hover,
fieldset[disabled] button.btn.btn-success:focus:hover,
fieldset[disabled] button.btn.btn-success:hover,
fieldset[disabled] button.btn.btn-success:hover:hover {
    -webkit-transform: none;
    transform: none
}

button.btn.btn-success.active,
button.btn.btn-success.focus,
button.btn.btn-success:active,
button.btn.btn-success:focus,
button.btn.btn-success:hover {
    background: #90b900;
    border-color: #78a100
}

button.btn.btn-success:active,
button.btn.btn-success:target {
    background-color: #7a9d00
}

button.btn.btn-info {
    background: #2dacd1;
    border-color: #2dacd1
}

button.btn.btn-info.disabled,
button.btn.btn-info.disabled.active,
button.btn.btn-info.disabled.focus,
button.btn.btn-info.disabled:active,
button.btn.btn-info.disabled:focus,
button.btn.btn-info.disabled:hover,
button.btn.btn-info[disabled],
button.btn.btn-info[disabled].active,
button.btn.btn-info[disabled].focus,
button.btn.btn-info[disabled]:active,
button.btn.btn-info[disabled]:focus,
button.btn.btn-info[disabled]:hover,
fieldset[disabled] button.btn.btn-info,
fieldset[disabled] button.btn.btn-info.active,
fieldset[disabled] button.btn.btn-info.focus,
fieldset[disabled] button.btn.btn-info:active,
fieldset[disabled] button.btn.btn-info:focus,
fieldset[disabled] button.btn.btn-info:hover {
    background: #2dacd1;
    border-color: #39b8dd
}

button.btn.btn-info.disabled.active:hover,
button.btn.btn-info.disabled.focus:hover,
button.btn.btn-info.disabled:active:hover,
button.btn.btn-info.disabled:focus:hover,
button.btn.btn-info.disabled:hover,
button.btn.btn-info.disabled:hover:hover,
button.btn.btn-info[disabled].active:hover,
button.btn.btn-info[disabled].focus:hover,
button.btn.btn-info[disabled]:active:hover,
button.btn.btn-info[disabled]:focus:hover,
button.btn.btn-info[disabled]:hover,
button.btn.btn-info[disabled]:hover:hover,
fieldset[disabled] button.btn.btn-info.active:hover,
fieldset[disabled] button.btn.btn-info.focus:hover,
fieldset[disabled] button.btn.btn-info:active:hover,
fieldset[disabled] button.btn.btn-info:focus:hover,
fieldset[disabled] button.btn.btn-info:hover,
fieldset[disabled] button.btn.btn-info:hover:hover {
    -webkit-transform: none;
    transform: none
}

button.btn.btn-info.active,
button.btn.btn-info.focus,
button.btn.btn-info:active,
button.btn.btn-info:focus,
button.btn.btn-info:hover {
    background: #2dacd1;
    border-color: #1594b9
}

button.btn.btn-info:active,
button.btn.btn-info:target {
    background-color: #2692b2
}

button.btn.btn-warning {
    background: #dfb81c;
    border-color: #dfb81c
}

button.btn.btn-warning.disabled,
button.btn.btn-warning.disabled.active,
button.btn.btn-warning.disabled.focus,
button.btn.btn-warning.disabled:active,
button.btn.btn-warning.disabled:focus,
button.btn.btn-warning.disabled:hover,
button.btn.btn-warning[disabled],
button.btn.btn-warning[disabled].active,
button.btn.btn-warning[disabled].focus,
button.btn.btn-warning[disabled]:active,
button.btn.btn-warning[disabled]:focus,
button.btn.btn-warning[disabled]:hover,
fieldset[disabled] button.btn.btn-warning,
fieldset[disabled] button.btn.btn-warning.active,
fieldset[disabled] button.btn.btn-warning.focus,
fieldset[disabled] button.btn.btn-warning:active,
fieldset[disabled] button.btn.btn-warning:focus,
fieldset[disabled] button.btn.btn-warning:hover {
    background: #dfb81c;
    border-color: #ebc428
}

button.btn.btn-warning.disabled.active:hover,
button.btn.btn-warning.disabled.focus:hover,
button.btn.btn-warning.disabled:active:hover,
button.btn.btn-warning.disabled:focus:hover,
button.btn.btn-warning.disabled:hover,
button.btn.btn-warning.disabled:hover:hover,
button.btn.btn-warning[disabled].active:hover,
button.btn.btn-warning[disabled].focus:hover,
button.btn.btn-warning[disabled]:active:hover,
button.btn.btn-warning[disabled]:focus:hover,
button.btn.btn-warning[disabled]:hover,
button.btn.btn-warning[disabled]:hover:hover,
fieldset[disabled] button.btn.btn-warning.active:hover,
fieldset[disabled] button.btn.btn-warning.focus:hover,
fieldset[disabled] button.btn.btn-warning:active:hover,
fieldset[disabled] button.btn.btn-warning:focus:hover,
fieldset[disabled] button.btn.btn-warning:hover,
fieldset[disabled] button.btn.btn-warning:hover:hover {
    -webkit-transform: none;
    transform: none
}

button.btn.btn-warning.active,
button.btn.btn-warning.focus,
button.btn.btn-warning:active,
button.btn.btn-warning:focus,
button.btn.btn-warning:hover {
    background: #dfb81c;
    border-color: #c7a004
}

button.btn.btn-warning:active,
button.btn.btn-warning:target {
    background-color: #be9c18
}

button.btn.btn-danger {
    background: #e85656;
    border-color: #e85656
}

button.btn.btn-danger.disabled,
button.btn.btn-danger.disabled.active,
button.btn.btn-danger.disabled.focus,
button.btn.btn-danger.disabled:active,
button.btn.btn-danger.disabled:focus,
button.btn.btn-danger.disabled:hover,
button.btn.btn-danger[disabled],
button.btn.btn-danger[disabled].active,
button.btn.btn-danger[disabled].focus,
button.btn.btn-danger[disabled]:active,
button.btn.btn-danger[disabled]:focus,
button.btn.btn-danger[disabled]:hover,
fieldset[disabled] button.btn.btn-danger,
fieldset[disabled] button.btn.btn-danger.active,
fieldset[disabled] button.btn.btn-danger.focus,
fieldset[disabled] button.btn.btn-danger:active,
fieldset[disabled] button.btn.btn-danger:focus,
fieldset[disabled] button.btn.btn-danger:hover {
    background: #e85656;
    border-color: #f46262
}

button.btn.btn-danger.disabled.active:hover,
button.btn.btn-danger.disabled.focus:hover,
button.btn.btn-danger.disabled:active:hover,
button.btn.btn-danger.disabled:focus:hover,
button.btn.btn-danger.disabled:hover,
button.btn.btn-danger.disabled:hover:hover,
button.btn.btn-danger[disabled].active:hover,
button.btn.btn-danger[disabled].focus:hover,
button.btn.btn-danger[disabled]:active:hover,
button.btn.btn-danger[disabled]:focus:hover,
button.btn.btn-danger[disabled]:hover,
button.btn.btn-danger[disabled]:hover:hover,
fieldset[disabled] button.btn.btn-danger.active:hover,
fieldset[disabled] button.btn.btn-danger.focus:hover,
fieldset[disabled] button.btn.btn-danger:active:hover,
fieldset[disabled] button.btn.btn-danger:focus:hover,
fieldset[disabled] button.btn.btn-danger:hover,
fieldset[disabled] button.btn.btn-danger:hover:hover {
    -webkit-transform: none;
    transform: none
}

button.btn.btn-danger.active,
button.btn.btn-danger.focus,
button.btn.btn-danger:active,
button.btn.btn-danger:focus,
button.btn.btn-danger:hover {
    background: #e85656;
    border-color: #d03e3e
}

button.btn.btn-danger:active,
button.btn.btn-danger:target {
    background-color: #c54949
}

button.btn.btn-inverse {
    background: #949494;
    border-color: #949494;
    color: #fff
}

button.btn.btn-inverse.disabled,
button.btn.btn-inverse.disabled.active,
button.btn.btn-inverse.disabled.focus,
button.btn.btn-inverse.disabled:active,
button.btn.btn-inverse.disabled:focus,
button.btn.btn-inverse.disabled:hover,
button.btn.btn-inverse[disabled],
button.btn.btn-inverse[disabled].active,
button.btn.btn-inverse[disabled].focus,
button.btn.btn-inverse[disabled]:active,
button.btn.btn-inverse[disabled]:focus,
button.btn.btn-inverse[disabled]:hover,
fieldset[disabled] button.btn.btn-inverse,
fieldset[disabled] button.btn.btn-inverse.active,
fieldset[disabled] button.btn.btn-inverse.focus,
fieldset[disabled] button.btn.btn-inverse:active,
fieldset[disabled] button.btn.btn-inverse:focus,
fieldset[disabled] button.btn.btn-inverse:hover {
    background: #949494;
    border-color: #a0a0a0
}

button.btn.btn-inverse.disabled.active:hover,
button.btn.btn-inverse.disabled.focus:hover,
button.btn.btn-inverse.disabled:active:hover,
button.btn.btn-inverse.disabled:focus:hover,
button.btn.btn-inverse.disabled:hover,
button.btn.btn-inverse.disabled:hover:hover,
button.btn.btn-inverse[disabled].active:hover,
button.btn.btn-inverse[disabled].focus:hover,
button.btn.btn-inverse[disabled]:active:hover,
button.btn.btn-inverse[disabled]:focus:hover,
button.btn.btn-inverse[disabled]:hover,
button.btn.btn-inverse[disabled]:hover:hover,
fieldset[disabled] button.btn.btn-inverse.active:hover,
fieldset[disabled] button.btn.btn-inverse.focus:hover,
fieldset[disabled] button.btn.btn-inverse:active:hover,
fieldset[disabled] button.btn.btn-inverse:focus:hover,
fieldset[disabled] button.btn.btn-inverse:hover,
fieldset[disabled] button.btn.btn-inverse:hover:hover {
    -webkit-transform: none;
    transform: none
}

button.btn.btn-inverse.active,
button.btn.btn-inverse.focus,
button.btn.btn-inverse:active,
button.btn.btn-inverse:focus,
button.btn.btn-inverse:hover {
    background: #949494;
    border-color: #7c7c7c
}

button.btn.btn-inverse:active,
button.btn.btn-inverse:hover,
button.btn.btn-inverse:target {
    background-color: #949494;
    color: #fff
}

.btn-with-icon i {
    margin-right: 10px
}

.btn-group :hover,
.btn-toolbar :hover {
    -webkit-transform: none;
    transform: none
}

.btn-group button.btn.btn-primary {
    border-color: #149285
}

.btn-group button.btn.btn-primary:hover {
    border-color: #088679
}

.btn-group button.btn.btn-danger {
    border-color: #dc4a4a
}

.btn-group button.btn.btn-danger:hover {
    border-color: #d03e3e
}

.btn-group button.btn.btn-info {
    border-color: #21a0c5
}

.btn-group button.btn.btn-info:hover {
    border-color: #1594b9
}

.btn-group button.btn.btn-success {
    border-color: #84ad00
}

.btn-group button.btn.btn-success:hover {
    border-color: #78a100
}

.btn-group button.btn.btn-warning {
    border-color: #d3ac10
}

.btn-group button.btn.btn-warning:hover {
    border-color: #c7a004
}

.btn-group .dropdown-menu {
    margin-top: 0
}

.btn-toolbar {
    display: inline-block
}

.btn .caret {
    margin-left: 2px
}

button.progress-button .progress {
    margin-bottom: 0;
    border-radius: 0
}

button.progress-button:hover {
    -webkit-transform: none;
    transform: none
}

button.progress-button.progress-button-style-shrink.btn.disabled.progress-button-dir-horizontal:hover {
    -webkit-transform: scaleY(.3);
    transform: scaleY(.3)
}

button.progress-button.progress-button-style-shrink.btn.disabled.progress-button-dir-vertical:hover {
    -webkit-transform: scaleX(.1);
    transform: scaleX(.1)
}

button.progress-button.btn.btn-primary .content:after,
button.progress-button.btn.btn-primary .content:before {
    color: #000
}

button.progress-button.btn.btn-primary.progress-button-style-move-up .content,
button.progress-button.btn.btn-primary.progress-button-style-slide-down .content {
    background-color: #17746a
}

button.progress-button.btn.btn-primary.progress-button-style-lateral-lines .progress-inner {
    border-color: #17746a;
    background: 0 0
}

button.progress-button.btn.btn-primary .progress {
    background-color: #17746a;
    box-shadow: 0 1px 0 #17746a
}

button.progress-button.btn.btn-primary .progress-inner {
    background-color: #0f4943
}

button.progress-button.btn.btn-primary.progress-button-perspective {
    background: 0 0
}

button.progress-button.btn.btn-primary.progress-button-perspective .content {
    background-color: #e65100
}

button.progress-button.btn.btn-default .content:after,
button.progress-button.btn.btn-default .content:before {
    color: #999
}

button.progress-button.btn.btn-default.progress-button-style-move-up .content,
button.progress-button.btn.btn-default.progress-button-style-slide-down .content {
    background-color: #e6e6e6
}

button.progress-button.btn.btn-default.progress-button-style-lateral-lines .progress-inner {
    border-color: #e6e6e6;
    background: 0 0
}

button.progress-button.btn.btn-default .progress {
    background-color: #e6e6e6;
    box-shadow: 0 1px 0 #e6e6e6
}

button.progress-button.btn.btn-default .progress-inner {
    background-color: #ccc
}

button.progress-button.btn.btn-default.progress-button-perspective {
    background: 0 0
}

button.progress-button.btn.btn-default.progress-button-perspective .content {
    background-color: #fff
}

button.progress-button.btn.btn-success .content:after,
button.progress-button.btn.btn-success .content:before {
    color: #000
}

button.progress-button.btn.btn-success.progress-button-style-move-up .content,
button.progress-button.btn.btn-success.progress-button-style-slide-down .content {
    background-color: #688600
}

button.progress-button.btn.btn-success.progress-button-style-lateral-lines .progress-inner {
    border-color: #688600;
    background: 0 0
}

button.progress-button.btn.btn-success .progress {
    background-color: #688600;
    box-shadow: 0 1px 0 #688600
}

button.progress-button.btn.btn-success .progress-inner {
    background-color: #415300
}

button.progress-button.btn.btn-success.progress-button-perspective {
    background: 0 0
}

button.progress-button.btn.btn-success.progress-button-perspective .content {
    background-color: #90b900
}

button.progress-button.btn.btn-info .content:after,
button.progress-button.btn.btn-info .content:before {
    color: #092229
}

button.progress-button.btn.btn-info.progress-button-style-move-up .content,
button.progress-button.btn.btn-info.progress-button-style-slide-down .content {
    background-color: #2489a7
}

button.progress-button.btn.btn-info.progress-button-style-lateral-lines .progress-inner {
    border-color: #2489a7;
    background: 0 0
}

button.progress-button.btn.btn-info .progress {
    background-color: #2489a7;
    box-shadow: 0 1px 0 #2489a7
}

button.progress-button.btn.btn-info .progress-inner {
    background-color: #1b677d
}

button.progress-button.btn.btn-info.progress-button-perspective {
    background: 0 0
}

button.progress-button.btn.btn-info.progress-button-perspective .content {
    background-color: #2dacd1
}

button.progress-button.btn.btn-warning .content:after,
button.progress-button.btn.btn-warning .content:before {
    color: #2a2205
}

button.progress-button.btn.btn-warning.progress-button-style-move-up .content,
button.progress-button.btn.btn-warning.progress-button-style-slide-down .content {
    background-color: #b29316
}

button.progress-button.btn.btn-warning.progress-button-style-lateral-lines .progress-inner {
    border-color: #b29316;
    background: 0 0
}

button.progress-button.btn.btn-warning .progress {
    background-color: #b29316;
    box-shadow: 0 1px 0 #b29316
}

button.progress-button.btn.btn-warning .progress-inner {
    background-color: #846d11
}

button.progress-button.btn.btn-warning.progress-button-perspective {
    background: 0 0
}

button.progress-button.btn.btn-warning.progress-button-perspective .content {
    background-color: #dfb81c
}

button.progress-button.btn.btn-danger .content:after,
button.progress-button.btn.btn-danger .content:before {
    color: #640e0e
}

button.progress-button.btn.btn-danger.progress-button-style-move-up .content,
button.progress-button.btn.btn-danger.progress-button-style-slide-down .content {
    background-color: #e22929
}

button.progress-button.btn.btn-danger.progress-button-style-lateral-lines .progress-inner {
    border-color: #e22929;
    background: 0 0
}

button.progress-button.btn.btn-danger .progress {
    background-color: #e22929;
    box-shadow: 0 1px 0 #e22929
}

button.progress-button.btn.btn-danger .progress-inner {
    background-color: #be1a1a
}

button.progress-button.btn.btn-danger.progress-button-perspective {
    background: 0 0
}

button.progress-button.btn.btn-danger.progress-button-perspective .content {
    background-color: #e85656
}

.btn-raised {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .35)
}

.btn-mm {
    padding: 5px 11px;
    font-size: 13px
}

.btn-xm {
    padding: 8px 14px;
    font-size: 16px
}

.dropdown button.btn.btn-default.dropdown-toggle {
    color: #666;
    border: 1px solid #d6d6d6;
    background-color: transparent
}

.bootstrap-select button.btn-default:focus,
.datepicker button.btn.active span.ng-binding.text-info {
    color: #fff
}

.dropdown button.btn.btn-default.dropdown-toggle:active,
.dropdown button.btn.btn-default.dropdown-toggle:focus {
    background-color: #fff
}

.bootstrap-select .btn {
    -webkit-transition: none;
    transition: none
}

.datepicker .btn:hover {
    -webkit-transform: scale(1);
    transform: scale(1)
}

.datepicker button.btn.btn-default {
    background-color: transparent
}

.datepicker button.btn.btn-default.active {
    background-color: #2dacd1;
    color: #fff
}

.uib-datepicker-wrap {
    min-height: 270px
}

.uib-datepicker span.ng-binding.text-muted {
    color: #666
}

.uib-datepicker-popup {
    background-color: #fff;
    border-width: 0;
    color: #000
}

.uib-datepicker-popup .glyphicon,
.uib-datepicker-popup button.btn,
.uib-datepicker-popup button.btn .text-muted {
    color: #000
}

.i-face {
    background: url(../assets/img/face.svg) center no-repeat;
    background-size: contain
}

.i-money {
    background: url(../assets/img/money.svg) center no-repeat;
    background-size: contain
}

.i-person {
    background: url(../assets/img/person.svg) center no-repeat;
    background-size: contain
}

.i-refresh {
    background: url(../assets/img/refresh.svg) center no-repeat;
    background-size: contain
}

::-webkit-scrollbar {
    width: .5em;
    height: .5em
}

body.blur-theme::before,
body.mobile.blur-theme .body-bg::before {
    content: '';
    height: 100%;
    top: 0;
    left: 0;
    will-change: transform;
    z-index: -1;
    width: 100%
}

::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    cursor: pointer
}

::-webkit-scrollbar-track {
    background: 0 0
}

body {
    scrollbar-face-color: #d9d9d9;
    scrollbar-track-color: transparent;
    font: 14px/16px Roboto, sans-serif;
    color: #666;
    background-color: #F0F3F4
}

.socicon,
th {
    font-weight: 400
}

body,
html {
    min-height: 100%;
    min-width: 320px
}

body.blur-theme::before {
    position: fixed;
    background: url(../assets/img/blur-bg.jpg) center center no-repeat;
    background-size: cover
}

body.mobile {
    background: 0 0
}

body.mobile .body-bg {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-attachment: inherit;
    background-color: #F0F3F4
}

body.mobile.blur-theme .body-bg::before {
    position: fixed;
    background: url(../assets/img/blur-bg.jpg) center center no-repeat;
    background-size: cover
}

@media screen and (-ms-high-contrast:active),
(-ms-high-contrast:none) {
    body,
    html {
        height: 100%
    }
    html {
        overflow: hidden
    }
    body {
        overflow: auto
    }
}

a {
    -webkit-transition: color .5s ease;
    transition: color .5s ease
}

.body-bg {
    display: none
}

.al-header {
    display: block;
    height: 49px;
    margin: 0;
    background-repeat: repeat-x;
    position: relative;
    z-index: 905;
    color: #444
}

.al-main {
    margin-left: 180px;
    padding: 66px 0 34px;
    min-height: 500px
}

.al-footer {
    height: 34px;
    padding: 0 18px 0 180px;
    width: 100%;
    position: absolute;
    display: block;
    bottom: 0;
    font-size: 13px;
    color: #666;
    -webkit-transition: padding-left .5s ease;
    transition: padding-left .5s ease
}

.al-footer-main {
    float: left;
    margin-left: 15px
}

.al-copy {
    float: left
}

.al-footer-right {
    float: right;
    margin-right: 12px
}

.al-share,
.al-share li {
    float: left;
    list-style: none
}

.al-footer-right i {
    margin: 0 4px;
    color: #e85656;
    font-size: 12px
}

.al-footer-right a {
    margin-left: 4px;
    color: #666
}

.al-footer-right a:hover {
    color: #e85656
}

.al-share {
    margin: -6px 0 0 12px;
    padding: 0
}

.al-share li {
    margin-left: 16px
}

.al-share li i {
    cursor: pointer;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    color: #fff;
    padding: 6px;
    box-sizing: content-box;
    font-size: 16px
}

.al-share li i:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
}

.al-share li i.fa-facebook-square {
    color: #3b5998
}

.al-share li i.fa-twitter-square {
    color: #55acee
}

.al-share li i.fa-google-plus-square {
    color: #dd4b39
}

.irs-grid-text,
a.email-link {
    color: #666
}

.al-content {
    padding: 8px 32px 8px 40px
}

@media screen and (max-width:500px) {
    .al-content {
        padding: 8px 20px
    }
}

.vis-hidden {
    visibility: hidden;
    position: absolute;
    top: -9999px;
    left: -9999px
}

.icon-down,
.icon-up {
    width: 5px;
    height: 13px;
    display: block
}

.icon-up {
    background: url(../assets/img/arrow-green-up.svg) no-repeat
}

.icon-down {
    background: url(../assets/img/arrow-red-down.svg) no-repeat
}

.disable-text-selection {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.align-right {
    text-align: right
}

.table>tbody>tr>td:first-child,
.table>thead>tr>th:first-child {
    text-align: center
}

.amcharts-chart-div>a {
    font-size: 6px!important
}

.content-panel {
    padding-left: 22px;
    padding-top: 26px
}

@media (max-width:590px) {
    .al-footer-right {
        float: none;
        margin-bottom: 19px;
        margin-right: 0
    }
    .al-footer {
        height: 76px;
        text-align: center
    }
    .al-main {
        padding-bottom: 76px
    }
    .al-footer-main {
        float: none;
        display: inline-block
    }
}

.full-invisible,
.full-invisible * {
    visibility: hidden!important
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: #000;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

#preloader>div {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #e85656;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite
}

#preloader>div:after,
#preloader>div:before {
    content: "";
    position: absolute
}

#preloader>div:before {
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #e65100;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite
}

#preloader>div:after {
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #dfb81c;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite
}

.socicon,
.st-sort-ascent,
.st-sort-descent {
    position: relative
}

@font-face {
    font-family: socicon;
    src: url(../assets/fonts/socicon.eot);
    src: url(../assets/fonts/socicon.eot?#iefix) format("embedded-opentype"), url(../assets/fonts/socicon.woff) format("woff"), url(../assets/fonts/socicon.woff2) format("woff2"), url(../assets/fonts/socicon.ttf) format("truetype"), url(../assets/fonts/socicon.svg#sociconregular) format("svg");
    font-weight: 400;
    font-style: normal;
    text-transform: initial
}

.socicon {
    font-family: socicon!important;
    top: 1px;
    display: inline-block;
    font-style: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased
}

.socicon:empty {
    width: 1em
}

.socicon-twitter {
    background-color: #55acee
}

.socicon-twitter:before {
    content: "a"
}

.socicon-facebook {
    background-color: #3b5998
}

.socicon-facebook:before {
    content: "b"
}

.socicon-google {
    background-color: #dd4b39
}

.socicon-google:before {
    content: "c"
}

.socicon-linkedin {
    background-color: #0177B5
}

.socicon-linkedin:before {
    content: "j"
}

.socicon-github {
    background-color: #6b6b6b
}

.socicon-github:before {
    content: "Q"
}

.socicon-stackoverflow {
    background-color: #2F96E8
}

.socicon-stackoverflow:before {
    content: "("
}

.socicon-dribble {
    background-color: #F26798
}

.socicon-dribble:before {
    content: "D"
}

.socicon-behace {
    background-color: #0093FA
}

.socicon-behace:before {
    content: "H"
}

.table {
    margin-bottom: 0
}

.table>thead>tr>th:last-child {
    padding-right: 16px
}

.table>tbody>tr>tr:first-child {
    padding-top: 1px
}

.table>tbody>tr>td {
    padding: 0 8px;
    line-height: 35px;
    border-top: 1px solid #dcdcdc
}

.table>tbody>tr>td:last-child {
    padding-right: 16px!important
}

.table-id {
    text-align: left!important;
    width: 40px
}

.table-arr {
    width: 5px;
    padding: 10px 8px 8px 0!important
}

.table-no-borders {
    border: none
}

.table-no-borders td,
.table-no-borders th,
.table-no-borders tr {
    border: none!important
}

.editable-wrap .btn-group.form-control {
    background-color: transparent
}

.editable-tr-wrap .editable-wrap {
    vertical-align: super
}

.table .header-row th,
tr.editable-row input.form-control {
    vertical-align: middle
}

.editable-tr-wrap .editable-controls input.editable-input {
    width: 110px
}

.editable-tr-wrap td {
    width: 20%
}

.editable-table-button {
    width: 70px
}

.add-row-editable-table {
    margin-bottom: 10px
}

.add-row-editable-table+table {
    margin-bottom: 5px
}

.select-page-size-wrap {
    width: 150px
}

.table .header-row th {
    padding: 0 8px
}

.select-td .editable-select {
    margin-bottom: 1px
}

@media screen and (max-width:1199px) {
    .editable-tr-wrap .editable-wrap {
        vertical-align: middle
    }
}

.browser-icons {
    width: 41px
}

.st-sort-ascent:after,
.st-sort-descent:after {
    width: 0;
    height: 0;
    border-bottom: 4px solid #666;
    border-top: 4px solid transparent;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    margin-bottom: 2px
}

.st-sort-descent:after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    margin-bottom: -2px
}

.sortable th {
    cursor: pointer
}

.sortable th:after {
    content: '';
    display: inline-block;
    width: 8px;
    margin-left: 8px
}

a.email-link:hover {
    color: #e85656
}

input.search-input {
    margin-left: -8px;
    padding-left: 8px
}

.table .pagination {
    margin: 4px 0 -12px
}

.table .pagination a {
    cursor: pointer
}

.vertical-scroll {
    max-height: 214px
}

.pagination>li>a,
.pagination>li>span {
    background: 0 0
}

.black-muted-bg,
.table-hover>tbody>tr:hover,
.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .1)
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.status-button {
    width: 60px
}

.table .editable-wrap .editable-controls,
.table .editable-wrap .editable-error {
    vertical-align: sub
}

.table .editable-wrap .editable-controls .btn,
.table .editable-wrap .editable-error .btn {
    padding: 3px 8px
}

.table .editable-wrap .editable-controls .btn.dropdown-toggle,
.table .editable-wrap .editable-error .btn.dropdown-toggle {
    padding: 3px 20px;
    margin-top: 3px
}

.leaflet-top .leaflet-control,
.table-responsive {
    margin-top: 10px
}

.table .editable-wrap .editable-controls input,
.table .editable-wrap .editable-error input {
    line-height: 1px;
    height: 30px
}

#google-maps,
#leaflet-map {
    height: calc(100vh - 283px)
}

.form-inline button[type=submit].editable-table-button {
    margin-left: 0
}

.table>thead>tr>th {
    white-space: nowrap;
    border-bottom: none
}

.table>tbody>tr.no-top-border:first-child>td {
    border-top: none
}

.table-bordered,
.table-bordered>tbody>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
    border: 1px solid #dcdcdc
}

.table>tbody>tr.primary>td {
    background-color: rgba(32, 158, 145, .7);
    color: #fff;
    border: none
}

.table>tbody>tr.primary>td a.email-link {
    color: #fff
}

.table>tbody>tr.primary>td a.email-link:hover {
    color: #e85656
}

.table>tbody>tr.success>td {
    background-color: rgba(144, 185, 0, .7);
    color: #fff;
    border: none
}

.table>tbody>tr.success>td a.email-link {
    color: #fff
}

.table>tbody>tr.success>td a.email-link:hover {
    color: #e85656
}

.table>tbody>tr.warning>td {
    background-color: rgba(223, 184, 28, .7);
    color: #fff;
    border: none
}

.table>tbody>tr.warning>td a.email-link {
    color: #fff
}

.table>tbody>tr.warning>td a.email-link:hover {
    color: #e85656
}

.table>tbody>tr.danger>td {
    background-color: rgba(232, 86, 86, .7);
    color: #fff;
    border: none
}

.table>tbody>tr.danger>td a.email-link {
    color: #fff
}

.table>tbody>tr.danger>td a.email-link:hover {
    color: #e85656
}

.table>tbody>tr.info>td {
    background-color: rgba(45, 172, 209, .7);
    color: #fff;
    border: none
}

.table>tbody>tr.info>td a.email-link {
    color: #fff
}

.table>tbody>tr.info>td a.email-link:hover {
    color: #e85656
}

.editable-click,
a.editable-click {
    color: #666;
    border-bottom: dashed 1px #d6d6d6
}

.editable-empty {
    color: #c54949
}

.table-striped>tbody>tr>td,
.table>tbody>tr>th {
    border: none
}

.pagination>li>a,
.pagination>li>span {
    color: #666;
    border-color: #d6d6d6
}

.pagination>li:first-of-type>a,
.pagination>li:first-of-type>span {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px
}

.pagination>li:last-of-type>a,
.pagination>li:last-of-type>span {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    background-color: #e65100;
    border-color: #d6d6d6
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
    background-color: rgba(0, 0, 0, .2);
    color: #666
}

.editable-buttons .btn-with-icon i {
    margin-right: 0
}

#google-maps {
    width: 100%
}

.leaflet-image-layer,
.leaflet-layer,
.leaflet-map-pane,
.leaflet-marker-icon,
.leaflet-marker-pane,
.leaflet-marker-shadow,
.leaflet-overlay-pane,
.leaflet-overlay-pane svg,
.leaflet-popup-pane,
.leaflet-shadow-pane,
.leaflet-tile,
.leaflet-tile-container,
.leaflet-tile-pane,
.leaflet-zoom-box {
    position: absolute;
    left: 0;
    top: 0
}

.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
    display: block
}

.leaflet-container img {
    max-width: none!important
}

.leaflet-container img.leaflet-image-layer {
    max-width: 15000px!important
}

.leaflet-tile {
    -webkit-filter: inherit;
    filter: inherit;
    visibility: hidden
}

.leaflet-tile-loaded {
    visibility: inherit
}

.leaflet-zoom-box {
    width: 0;
    height: 0
}

.leaflet-overlay-pane svg {
    -moz-user-select: none
}

.leaflet-tile-pane {
    z-index: 2
}

.leaflet-objects-pane {
    z-index: 3
}

.leaflet-overlay-pane {
    z-index: 4
}

.leaflet-shadow-pane {
    z-index: 5
}

.leaflet-marker-pane {
    z-index: 6
}

.leaflet-popup-pane {
    z-index: 7
}

.leaflet-vml-shape {
    width: 1px;
    height: 1px
}

.leaflet-bar .leaflet-control-zoom-in:hover,
.leaflet-bar .leaflet-control-zoom-out:hover,
.leaflet-bar a {
    width: 20px;
    height: 20px
}

.lvml {
    behavior: url(#default#VML);
    display: inline-block;
    position: absolute
}

.leaflet-control {
    position: relative;
    z-index: 7;
    pointer-events: auto;
    float: left;
    clear: both
}

.leaflet-bottom,
.leaflet-top {
    position: absolute;
    z-index: 1000;
    pointer-events: none
}

.leaflet-top {
    top: 0
}

.leaflet-right {
    right: 0
}

.leaflet-bottom {
    bottom: 0
}

.leaflet-left {
    left: 0
}

.leaflet-right .leaflet-control {
    float: right;
    margin-right: 10px
}

.leaflet-bottom .leaflet-control {
    margin-bottom: 10px
}

.leaflet-left .leaflet-control {
    margin-left: 10px
}

.leaflet-fade-anim .leaflet-popup,
.leaflet-fade-anim .leaflet-tile {
    opacity: 0;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup,
.leaflet-fade-anim .leaflet-tile-loaded {
    opacity: 1
}

.leaflet-zoom-anim .leaflet-zoom-animated {
    -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
    transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
    transition: transform .25s cubic-bezier(0, 0, .25, 1);
    transition: transform .25s cubic-bezier(0, 0, .25, 1), -webkit-transform .25s cubic-bezier(0, 0, .25, 1)
}

.leaflet-pan-anim .leaflet-tile,
.leaflet-touching .leaflet-zoom-animated,
.leaflet-zoom-anim .leaflet-tile {
    -webkit-transition: none;
    transition: none
}

.leaflet-zoom-anim .leaflet-zoom-hide {
    visibility: hidden
}

.leaflet-clickable {
    cursor: pointer
}

.leaflet-container {
    overflow: hidden;
    -ms-touch-action: none;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    background: #ddd;
    outline: 0
}

.leaflet-control,
.leaflet-popup-pane {
    cursor: auto
}

.leaflet-dragging .leaflet-clickable,
.leaflet-dragging .leaflet-container {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing
}

.leaflet-container a {
    color: #0078A8
}

.leaflet-container a.leaflet-active {
    outline: orange solid 2px
}

.leaflet-zoom-box {
    border: 2px dotted #38f;
    background: rgba(255, 255, 255, .5)
}

.leaflet-bar {
    box-shadow: 0 1px 5px rgba(0, 0, 0, .65)
}

.leaflet-bar a {
    background-color: #e65100;
    border-bottom: 1px solid #ccc;
    display: block;
    text-align: center;
    opacity: .8;
    color: #666;
    font-size: 17px;
    line-height: 18px;
    font-family: Lato;
    font-weight: 100
}

.leaflet-bar a:hover {
    background-color: #e85656
}

.leaflet-bar a:last-child {
    border-bottom: none
}

.leaflet-bar a.leaflet-control-zoom-out {
    line-height: 16px
}

.leaflet-bar a.leaflet-disabled {
    cursor: default;
    background-color: #e2e2e2;
    color: #ddd
}

.leaflet-bar .leaflet-control-zoom-in,
.leaflet-bar .leaflet-control-zoom-out {
    line-height: 18px
}

.leaflet-control-zoom-out {
    font-size: 20px
}

.leaflet-touch .leaflet-control-zoom-in {
    font-size: 22px
}

.leaflet-touch .leaflet-control-zoom-out {
    font-size: 24px
}

.leaflet-control-layers {
    box-shadow: 0 1px 5px rgba(0, 0, 0, .4);
    background: #fff
}

.leaflet-control-layers-toggle {
    background-image: url(../img/layers.png);
    width: 36px;
    height: 36px
}

.leaflet-retina .leaflet-control-layers-toggle {
    background-image: url(../img/layers-2x.png);
    background-size: 26px 26px
}

.leaflet-touch .leaflet-control-layers-toggle {
    width: 44px;
    height: 44px
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
    display: none
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
    display: block;
    position: relative
}

.leaflet-control-layers-expanded {
    padding: 6px 10px 6px 6px;
    color: #333;
    background: #fff
}

.leaflet-control-layers-selector {
    margin-top: 2px;
    position: relative;
    top: 1px
}

.leaflet-control-layers label {
    display: block
}

.leaflet-control-layers-separator {
    height: 0;
    border-top: 1px solid #ddd;
    margin: 5px -10px 5px -6px
}

.leaflet-container .leaflet-control-attribution {
    background: #fff;
    background: rgba(255, 255, 255, .7);
    margin: 0
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
    padding: 0 5px;
    color: #333
}

.leaflet-control-attribution a:hover {
    text-decoration: underline
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
    font-size: 11px
}

.leaflet-left .leaflet-control-scale {
    margin-left: 5px
}

.leaflet-bottom .leaflet-control-scale {
    margin-bottom: 5px
}

.leaflet-control-scale-line {
    border: 2px solid #777;
    border-top: none;
    line-height: 1.1;
    padding: 2px 5px 1px;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: content-box;
    background: #fff;
    background: rgba(255, 255, 255, .5)
}

.leaflet-control-scale-line:not(:first-child) {
    border-top: 2px solid #777;
    border-bottom: none;
    margin-top: -2px
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
    border-bottom: 2px solid #777
}

.leaflet-touch .leaflet-bar,
.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers {
    box-shadow: none
}

.leaflet-touch .leaflet-bar,
.leaflet-touch .leaflet-control-layers {
    border: none;
    background-clip: padding-box
}

.leaflet-popup {
    position: absolute;
    text-align: center
}

.leaflet-popup-content-wrapper {
    padding: 1px;
    text-align: left
}

.leaflet-popup-content {
    margin: 13px 19px;
    line-height: 1.4;
    color: #7d7d7d
}

.leaflet-popup-content p {
    margin: 18px 0
}

.leaflet-popup-tip-container {
    margin: 0 auto;
    width: 40px;
    height: 20px;
    position: relative;
    overflow: hidden
}

.leaflet-popup-tip {
    width: 17px;
    height: 17px;
    padding: 1px;
    margin: -10px auto 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
    background: #fff;
    box-shadow: 0 3px 14px rgba(0, 0, 0, .4)
}

.leaflet-container a.leaflet-popup-close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 4px 0 0;
    text-align: center;
    width: 18px;
    height: 14px;
    font: 16px/14px Tahoma, Verdana, sans-serif;
    color: #c3c3c3;
    text-decoration: none;
    font-weight: 700;
    background: 0 0
}

.leaflet-container a.leaflet-popup-close-button:hover {
    color: #999
}

.panel,
.panel>.panel-footer {
    color: #666
}

.leaflet-popup-scrolled {
    overflow: auto;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd
}

.leaflet-oldie .leaflet-popup-content-wrapper {
    zoom: 1
}

.leaflet-oldie .leaflet-popup-tip {
    width: 24px;
    margin: 0 auto;
    -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=.70710678, M12=.70710678, M21=-.70710678, M22=.70710678)
}

#map-bubbles,
#map-lines {
    width: 100%;
    height: calc(100vh - 283px)
}

.leaflet-oldie .leaflet-popup-tip-container {
    margin-top: -1px
}

.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
    border: 1px solid #999
}

.leaflet-div-icon {
    background: #fff;
    border: 1px solid #666
}

#map-bubbles {
    font-size: 11px
}

.panel {
    background-color: #fff;
    border: none;
    border-radius: 5px;
    position: relative;
    margin-bottom: 24px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .25)
}

.feed-message,
.panel-footer,
.panel-heading {
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, .12)
}

.panel ::-webkit-scrollbar {
    width: .4em;
    height: .4em
}

.panel ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .6);
    cursor: pointer
}

.panel ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, .7)
}

.panel body {
    scrollbar-face-color: rgba(0, 0, 0, .6);
    scrollbar-track-color: rgba(255, 255, 255, .7)
}

.panel.animated {
    -webkit-animation-duration: .5s;
    animation-duration: .5s
}

.panel.small-panel {
    height: 114px
}

.panel.xsmall-panel {
    height: 187px
}

.panel.medium-panel {
    height: 400px
}

.panel.xmedium-panel {
    height: 550px
}

.panel.large-panel {
    height: 974px
}

.panel.viewport100 {
    height: calc(100vh - 218px)
}

.panel.with-scroll .panel-body {
    height: calc(100% - 44px);
    overflow-y: auto
}

.panel.panel-blur {
    background: url(../assets/img/blur-bg-blurred.jpg) fixed;
    -webkit-transition: none;
    transition: none
}

.panel>.panel-body {
    padding: 15px 22px;
    height: 100%
}

.panel>.panel-heading {
    color: #666;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.bootstrap-panel .panel-body,
.panel-default>.panel-footer,
.panel-default>.panel-heading {
    color: #7d7d7d
}

.panel-footer,
.panel-heading {
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    height: 44px;
    font-size: 16px;
    padding: 14px 22px
}

.panel-title {
    font-weight: 400;
    opacity: .9
}

.panel-primary>.panel-heading {
    background-color: #4db1a7;
    border-color: #4db1a7
}

.panel-success>.panel-heading {
    background-color: #a6c733;
    border-color: #a6c733
}

.panel-info>.panel-heading {
    background-color: #57bdda;
    border-color: #57bdda
}

.panel-warning>.panel-heading {
    background-color: #e5c649;
    border-color: #e5c649
}

.panel-danger>.panel-heading {
    background-color: #ed7878;
    border-color: #ed7878
}

.bootstrap-panel {
    background-color: #fff
}

.accordion-panel.panel.panel-danger .panel-heading div,
.accordion-panel.panel.panel-danger .panel-heading p,
.accordion-panel.panel.panel-danger .panel-heading span,
.accordion-panel.panel.panel-info .panel-heading div,
.accordion-panel.panel.panel-info .panel-heading p,
.accordion-panel.panel.panel-info .panel-heading span,
.accordion-panel.panel.panel-primary .panel-heading div,
.accordion-panel.panel.panel-primary .panel-heading p,
.accordion-panel.panel.panel-primary .panel-heading span,
.accordion-panel.panel.panel-success .panel-heading div,
.accordion-panel.panel.panel-success .panel-heading p,
.accordion-panel.panel.panel-success .panel-heading span,
.accordion-panel.panel.panel-warning .panel-heading div,
.accordion-panel.panel.panel-warning .panel-heading p,
.accordion-panel.panel.panel-warning .panel-heading span {
    color: rgba(255, 255, 255, .8)
}

.panel-group .panel.accordion-panel .panel-heading {
    border-bottom: 0
}

.panel-group .panel .panel-heading {
    border-bottom: 1px solid #ddd
}

.p-with-code {
    line-height: 1.5em
}

.contextual-example-panel {
    height: 120px
}

.footer-panel {
    height: 142px
}

.light-text {
    font-weight: 300
}

.tabs-below .nav-tabs,
.tabs-left .nav-tabs,
.tabs-right .nav-tabs {
    border-bottom: 0
}

.tabs-left .nav-tabs,
.tabs-right .nav-tabs {
    min-width: 100px
}

.tabs-left .tab-content,
.tabs-right .tab-content {
    width: calc(100% - 100px);
    overflow-y: auto
}

.tabs-right .tab-content {
    margin-right: 100px
}

.tabs-left .tab-content {
    margin-left: 100px
}

.pill-content>.pill-pane,
.tab-content>.tab-pane {
    display: none
}

.pill-content>.active,
.tab-content>.active {
    display: block
}

.tabs-below>.nav-tabs>li {
    margin-top: -1px;
    margin-bottom: 0
}

.tabs-left,
.tabs-right {
    height: 100%
}

.tabs-left>.nav-tabs>li,
.tabs-right>.nav-tabs>li {
    float: none;
    margin-bottom: 0
}

.tabs-left>.nav-tabs>li>a,
.tabs-right>.nav-tabs>li>a {
    min-width: 74px;
    margin-right: 0;
    margin-bottom: 3px
}

.tabs-left>.nav-tabs {
    float: left;
    border-bottom-left-radius: 5px
}

.tabs-left>.nav-tabs>li>a {
    margin-right: -1px
}

.tabs-right>.nav.nav-tabs {
    float: right;
    border-top-left-radius: 0;
    border-bottom-right-radius: 5px
}

.tabs-right>.nav.nav-tabs>li:first-of-type a {
    border-top-left-radius: 0
}

.nav-tabs>li.with-dropdown>a {
    padding: 0
}

.nav-tabs>li.with-dropdown .dropdown-toggle {
    padding: 10px 15px;
    display: inline-block;
    cursor: pointer
}

.tab-content {
    padding: 15px 15px 5px;
    background: 0 0;
    color: #666
}

.tab-content .tab-pane p {
    color: #666
}

.nav.nav-tabs {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid transparent;
    background-color: #ff9800
}

.nav.nav-tabs a,
.nav.nav-tabs a:hover {
    color: #fff
}

.nav.nav-tabs>li>a {
    margin-right: 0;
    margin-bottom: 0;
    border-radius: 0;
    border: none
}

.nav.nav-tabs>li>a:focus,
.nav.nav-tabs>li>a:hover {
    border: none;
    background-color: #e65100
}

.nav.nav-tabs>li.active>a {
    color: #fff;
    background-color: #e65100
}

.blur-theme .nav.nav-tabs,
.blur-theme .nav.nav-tabs>li>a:hover {
    background-color: rgba(0, 0, 0, .2)
}

.nav.nav-tabs>li:first-of-type a {
    border-top-left-radius: 5px
}

.nav.nav-tabs .dropdown-menu>li>a,
.nav.nav-tabs .dropdown-menu>li>a:hover {
    color: #7d7d7d
}

.blur-theme .nav.nav-tabs a,
.blur-theme .nav.nav-tabs a:hover {
    color: #666
}

.blur-theme .nav.nav-tabs>li.active>a {
    color: #666;
    background-color: rgba(0, 0, 0, .25)
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
    background-color: transparent
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    border: none
}

.accordion-panel .panel-heading {
    border-radius: 3px;
    padding: 0
}

.accordion-panel.panel-open .panel-heading {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.accordion-panel:not(.panel-open) .panel-heading {
    -webkit-transition-delay: .3s;
    transition-delay: .3s
}

.accordion-panel>.panel-heading+.panel-collapse>.panel-body {
    border-top: none
}

.accordion-panel .panel-heading .accordion-toggle {
    display: inline-block;
    width: 100%;
    padding: 14px 22px
}

.back-top {
    width: 52px;
    height: 52px;
    cursor: pointer;
    z-index: 9999;
    display: none;
    text-decoration: none;
    right: 64px;
    bottom: 40px!important;
    font-size: 45px;
    text-align: center;
    opacity: .4;
    color: #e65100;
    background-color: rgba(0, 0, 0, .75);
    border-radius: 50%;
    line-height: 46px
}

.back-top:hover {
    opacity: .8
}

.ba-wizard-progress {
    margin-top: 10px;
    height: 10px
}

.ba-wizard-pager .previous {
    float: left
}

.ba-wizard-pager .next {
    float: right
}

.ba-wizard-navigation-container {
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: 15px 0
}

.ba-wizard-navigation-container .ba-wizard-navigation {
    cursor: pointer;
    display: table-cell;
    line-height: 1;
    text-align: center;
    font-weight: 300;
    font-size: 18px;
    opacity: .7
}

.ba-wizard-navigation-container .ba-wizard-navigation.active {
    opacity: .95
}

.content-top {
    padding-top: 13px;
    padding-bottom: 27px
}

h1.al-title {
    font-weight: 700;
    color: #666;
    float: left;
    width: auto;
    margin: 0;
    padding: 0;
    font-size: 24px;
    opacity: .9
}

.al-breadcrumb {
    background: 0 0;
    color: #666;
    padding: 11px 0 0;
    margin: 0;
    float: right
}

.al-breadcrumb li {
    font-size: 18px;
    font-weight: 400
}

.al-look {
    float: right;
    margin-right: 10px;
    padding-top: 10px
}

.al-look>a {
    font-size: 19px
}

@-webkit-keyframes pulsate {
    30% {
        -webkit-transform: scale(.1, .1);
        opacity: 0
    }
    35% {
        opacity: 1
    }
    40% {
        -webkit-transform: scale(1.2, 1.2);
        opacity: 0
    }
}

.al-msg-center {
    float: right;
    padding: 0;
    list-style: none;
    margin: 13px 47px 0 0
}

.al-msg-center li {
    list-style: none;
    float: left;
    margin-left: 30px
}

.al-msg-center li:first-child {
    margin-left: 0
}

.al-msg-center li>a {
    color: #fff;
    text-decoration: none;
    font-size: 13px;
    position: relative
}

.al-msg-center li>a span {
    display: inline-block;
    min-width: 10px;
    padding: 2px 4px;
    color: #fff;
    vertical-align: baseline;
    white-space: nowrap;
    text-align: center;
    border-radius: 13px;
    text-shadow: none;
    line-height: 11px;
    background-color: #e85656;
    position: absolute;
    top: -5px;
    right: -14px;
    font-size: 11px
}

.al-msg-center li>a .notification-ring {
    border: 1px solid #e85656;
    border-radius: 100px;
    height: 40px;
    width: 40px;
    position: absolute;
    top: -18px;
    right: -27px;
    -webkit-animation: pulsate 8s ease-out;
    animation: pulsate 8s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    opacity: 0
}

.al-msg-center li>a:hover {
    color: #e85656
}

.al-msg-center li>a:hover.msg {
    color: #e65100
}

.al-msg-center li>a.msg span {
    background-color: #e65100
}

.al-msg-center li>a.msg .notification-ring {
    border-color: #e65100
}

.al-msg-center li.open>a {
    color: #e85656
}

.al-msg-center li.open>a.msg {
    color: #e65100
}

@media (max-width:435px) {
    .al-msg-center {
        margin-right: 20px
    }
    .al-msg-center li {
        margin-left: 20px
    }
    .al-msg-center li:first-child {
        margin-left: 0
    }
}

.msg-block-header {
    display: inline-block;
    padding: 0;
    font-size: 13px;
    margin: 0 0 0 6px
}

.top-dropdown-menu {
    width: 316px;
    left: auto;
    right: -47px;
    top: 26px
}

.top-dropdown-menu ::-webkit-scrollbar {
    width: .4em;
    height: .4em
}

.top-dropdown-menu ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .5);
    cursor: pointer
}

.top-dropdown-menu ::-webkit-scrollbar-track {
    background: #fff
}

.top-dropdown-menu body {
    scrollbar-face-color: rgba(0, 0, 0, .5);
    scrollbar-track-color: #fff
}

.top-dropdown-menu .header {
    padding: 10px 12px;
    border-bottom: 1px solid #fff;
    font-size: 12px
}

.top-dropdown-menu .header strong {
    float: left;
    color: #7d7d7d
}

.top-dropdown-menu .header>a {
    float: right;
    margin-left: 12px;
    text-decoration: none
}

.top-dropdown-menu .header>a:hover {
    color: #7d7d7d
}

.top-dropdown-menu .msg-list {
    max-height: 296px;
    overflow: scroll;
    overflow-x: hidden
}

.top-dropdown-menu .msg-list>a {
    border-top: 1px solid #fff;
    padding: 10px 12px;
    display: block;
    text-decoration: none;
    color: #7d7d7d;
    font-size: 12px
}

.top-dropdown-menu .msg-list>a:first-child {
    border-top: none
}

.top-dropdown-menu .msg-list>a .img-area {
    float: left;
    width: 36px
}

.top-dropdown-menu .msg-list>a .img-area img {
    width: 36px;
    height: 36px
}

.top-dropdown-menu .msg-list>a .img-area img.photo-msg-item {
    border-radius: 18px
}

.top-dropdown-menu .msg-list>a .img-area>div {
    width: 36px;
    height: 36px;
    border-radius: 4px;
    font-size: 24px;
    text-align: center
}

.top-dropdown-menu .msg-list>a .img-area>div.comments,
.top-dropdown-menu .msg-list>a .img-area>div.orders {
    color: #dfb81c
}

.top-dropdown-menu .msg-list>a .img-area>div i {
    width: 36px;
    line-height: 36px
}

.top-dropdown-menu .msg-list>a .msg-area {
    float: right;
    width: 230px
}

.top-dropdown-menu .msg-list>a .msg-area div {
    max-height: 34px;
    overflow: hidden;
    text-overflow: ellipsis
}

.top-dropdown-menu .msg-list>a .msg-area span {
    font-style: italic;
    text-align: right;
    display: block;
    font-size: 11px
}

.top-dropdown-menu .msg-list>a:hover {
    background: #E2F0FF
}

.top-dropdown-menu>a {
    border-top: 1px solid #fff;
    display: block;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    text-decoration: none
}

.top-dropdown-menu>a:hover {
    color: #7d7d7d
}

.top-dropdown-menu.profile-dropdown {
    width: 145px;
    top: 55px;
    right: -25px
}

.top-dropdown-menu.profile-dropdown a {
    text-align: left;
    border: none;
    text-decoration: none;
    color: #7d7d7d;
    padding: 4px 16px 4px 20px
}

.top-dropdown-menu.profile-dropdown a.signout {
    border-top: 1px solid #fff
}

.top-dropdown-menu.profile-dropdown a i {
    margin-right: 10px
}

.top-dropdown-menu.profile-dropdown a:hover {
    background: #f4fcff
}

.top-dropdown-menu.profile-dropdown i.dropdown-arr {
    right: 25px
}

.top-dropdown-menu i.dropdown-arr {
    position: absolute;
    top: -22px;
    right: 42px;
    display: block;
    width: 0;
    height: 0;
    border: 11px solid transparent;
    border-bottom-color: rgba(0, 0, 0, .15)
}

.top-dropdown-menu i.dropdown-arr:after {
    top: -9px;
    left: 0;
    margin-left: -10px;
    content: " ";
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #fff
}

@media (max-width:415px) {
    .top-dropdown-menu {
        right: -81px
    }
    .top-dropdown-menu i.dropdown-arr {
        right: 75px
    }
}

.page-top {
    background-color: #1C2B36;
    position: fixed;
    z-index: 904;
    box-shadow: 2px 0 3px rgba(0, 0, 0, .5);
    height: 66px;
    width: 100%;
    min-width: 320px;
    padding: 0 32px 0 40px
}

.page-top.scrolled {
    background-color: rgba(0, 0, 0, .85)
}

a.al-logo {
    color: #fff;
    display: block;
    font-size: 24px;
    font-family: Roboto, sans-serif;
    white-space: nowrap;
    float: left;
    line-height: 60px
}

a.al-logo span {
    color: #e65100
}

.user-profile {
    float: right;
    min-width: 230px;
    margin-top: 10px
}

.al-user-profile {
    float: right;
    margin-right: 12px;
    -webkit-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
    padding: 0;
    width: 36px;
    height: 36px;
    border: 0;
    opacity: 1;
    position: relative
}

.al-user-profile a {
    display: block
}

.al-user-profile img {
    width: 45px;
    height: 45px;
    border-radius: 50%
}

a.refresh-data {
    color: #fff;
    font-size: 13px;
    text-decoration: none;
    font-weight: 400;
    float: right;
    margin-top: 13px;
    margin-right: 26px
}

a.refresh-data:hover {
    color: #dfb81c!important
}

a.collapse-menu-link {
    font-size: 31px;
    cursor: pointer;
    display: block;
    text-decoration: none;
    line-height: 42px;
    color: #fff;
    padding: 0;
    float: left;
    margin: 11px 0 0 25px
}

a.collapse-menu-link:hover {
    text-decoration: none;
    color: #dfb81c
}

.al-skin-dropdown {
    float: right;
    margin-top: 14px;
    margin-right: 26px
}

.al-skin-dropdown .tpl-skin-panel {
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden
}

.search,
a.al-sidebar-list-link {
    color: #fff;
    overflow: hidden;
    text-shadow: none;
    white-space: nowrap
}

.icon-palette {
    display: inline-block;
    width: 14px;
    height: 13px;
    background: url(../assets/img/theme/palette.png);
    background-size: cover
}

.search {
    font-size: 13px;
    line-height: 25px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    width: 162px;
    float: left;
    margin: 20px 0 0 30px
}

.search label {
    cursor: pointer
}

.search i {
    width: 16px;
    display: inline-block;
    cursor: pointer;
    padding-left: 1px;
    font-size: 16px;
    margin-right: 13px
}

.search input {
    background: 0 0;
    border: none;
    outline: 0;
    width: 120px;
    padding: 0;
    margin: 0 0 0 -3px;
    height: 27px
}

@media screen and (max-width:660px) {
    .search {
        display: none
    }
}

@media screen and (max-width:500px) {
    .page-top {
        padding: 0 20px
    }
}

@media (max-width:435px) {
    .user-profile {
        min-width: 136px
    }
    a.refresh-data {
        margin-right: 10px
    }
    a.collapse-menu-link {
        margin-left: 10px
    }
    .al-skin-dropdown {
        display: none
    }
}

.profile-toggle-link {
    cursor: pointer
}

svg.progress-bar-round circle {
    -webkit-transition: .5s;
    transition: .5s
}

.percentage {
    font-size: 46px
}

.loading {
    font-size: 16px
}

.progress-bar-round {
    margin-top: 15px
}

.al-sidebar {
    width: 180px;
    top: 66px;
    left: 0;
    z-index: 904;
    display: block;
    min-height: 100%;
    background-color: #1C2B36;
    height: 100%;
    position: fixed
}

.al-sidebar-list {
    margin: 0;
    padding: 18px 0 0;
    list-style: none
}

.al-sidebar-sublist .subitem-submenu-list {
    padding-left: 15px
}

.subitem-submenu-link .fa {
    top: 7px
}

.al-sidebar-list-item {
    display: block;
    position: relative;
    float: none;
    padding: 0
}

.al-sidebar-list-item.selected:not(.with-sub-menu) {
    background-color: #e65100
}

.al-sidebar-list-item.selected:not(.with-sub-menu) a.al-sidebar-list-link,
.al-sidebar-list-item.selected:not(.with-sub-menu) a.al-sidebar-list-link b {
    color: #fff
}

.al-sidebar-list-item.ba-sidebar-item-expanded>.al-sidebar-list-link b,
.ba-sidebar-sublist-item.ba-sidebar-item-expanded>.al-sidebar-list-link b {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.al-sidebar-list-item.ba-sidebar-item-expanded>.al-sidebar-sublist,
.ba-sidebar-sublist-item.ba-sidebar-item-expanded>.al-sidebar-sublist {
    display: block
}

a.al-sidebar-list-link {
    display: block;
    height: 42px;
    padding-left: 18px;
    font-size: 13px;
    text-decoration: none;
    line-height: 42px;
    cursor: pointer
}

a.al-sidebar-list-link:hover,
a.al-sidebar-list-link:hover b {
    color: #e65100
}

a.al-sidebar-list-link i {
    margin-right: 18px;
    width: 16px;
    display: inline-block
}

a.al-sidebar-list-link b {
    display: block;
    opacity: 1;
    width: 14px;
    height: 14px;
    line-height: 14px;
    text-shadow: none;
    font-size: 18px;
    position: absolute;
    right: 10px;
    top: 12px;
    padding: 0;
    text-align: center;
    color: #fff;
    -webkit-transition: -webkit-transform .2s linear;
    transition: -webkit-transform .2s linear;
    transition: transform .2s linear;
    transition: transform .2s linear, -webkit-transform .2s linear
}

.slimScrollBar,
.slimScrollRail {
    border-radius: 0!important;
    width: 4px!important;
    left: 176px
}

.al-sidebar-sublist {
    padding: 0;
    list-style: none;
    position: relative;
    display: none
}

.al-sidebar-sublist.expanded {
    display: block
}

.al-sidebar-sublist>li {
    display: block;
    float: none;
    padding: 0;
    border-bottom: none;
    position: relative
}

.al-sidebar-sublist>li a {
    display: block;
    text-shadow: none;
    font-size: 13px;
    text-decoration: none;
    color: #fff;
    padding-left: 52px;
    height: auto;
    line-height: 29px
}

.al-sidebar-sublist>li a:hover {
    color: #e65100
}

.al-sidebar-sublist>li.selected:not(.with-sub-menu)>a {
    border: none;
    background-color: #e65100
}

.al-sidebar-sublist>li.selected:not(.with-sub-menu)>a:hover {
    color: #fff
}

.sidebar-hover-elem {
    width: 4px;
    background: #e65100;
    position: absolute;
    top: -150px;
    left: 176px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    -webkit-transition-property: top, height;
    transition-property: top, height;
    height: 42px;
    display: block
}

.sidebar-select-elem {
    display: block;
    top: 94px
}

@media (min-width:1200px) {
    .menu-collapsed .al-main {
        margin-left: 50px
    }
    .menu-collapsed .al-footer {
        padding-left: 83px
    }
}

@media (min-width:501px) {
    .menu-collapsed .al-sidebar {
        width: 52px
    }
    .menu-collapsed .al-sidebar .fa-angle-down,
    .menu-collapsed .al-sidebar .fa-angle-up {
        opacity: 0
    }
    .menu-collapsed .al-sidebar .al-sidebar-sublist {
        position: absolute;
        top: -1px;
        left: 52px;
        background: rgba(0, 0, 0, .8);
        width: 0;
        display: block;
        overflow: hidden;
        -webkit-transition: width .5s ease;
        transition: width .5s ease
    }
    .menu-collapsed .al-sidebar .al-sidebar-sublist.slide-right {
        width: 135px
    }
    .menu-collapsed .al-sidebar .al-sidebar-sublist li:before,
    .menu-collapsed .al-sidebar .al-sidebar-sublist:before {
        display: none
    }
    .menu-collapsed .al-sidebar .al-sidebar-sublist li a {
        padding-left: 18px;
        padding-right: 18px;
        min-width: 130px;
        white-space: nowrap
    }
    .menu-collapsed .al-sidebar .sidebar-hover-elem,
    .menu-collapsed .al-sidebar .sidebar-select-elem {
        left: 48px
    }
}

@media (max-width:1200px) and (min-width:500px) {
    .al-main {
        margin-left: 50px
    }
    .al-footer {
        padding-left: 83px
    }
}

@media (max-width:1200px) {
    .al-sidebar {
        width: 180px;
        background: rgba(0, 0, 0, .75);
        -webkit-transition: width .5s ease;
        transition: width .5s ease
    }
    .al-sidebar .fa-angle-down,
    .al-sidebar .fa-angle-up {
        opacity: 1
    }
    .al-sidebar .al-sidebar-sublist {
        padding: 0;
        list-style: none;
        position: relative;
        display: none;
        top: auto;
        left: auto;
        background: 0 0;
        width: auto;
        overflow: visible;
        -webkit-transition: none;
        transition: none
    }
    .al-sidebar .al-sidebar-sublist.expanded {
        display: block
    }
    .al-sidebar .al-sidebar-sublist>li {
        display: block;
        float: none;
        padding: 0;
        border-bottom: none;
        position: relative
    }
    .al-sidebar .al-sidebar-sublist>li a {
        display: block;
        text-shadow: none;
        font-size: 13px;
        text-decoration: none;
        color: #fff;
        padding-left: 52px;
        height: auto;
        line-height: 29px
    }
    .al-sidebar .al-sidebar-sublist>li a:hover {
        color: #e65100
    }
    .al-sidebar .al-sidebar-sublist>li.selected:not(.with-sub-menu)>a {
        border: none;
        background-color: #e65100
    }
    .al-sidebar .al-sidebar-sublist>li.selected:not(.with-sub-menu)>a:hover {
        color: #fff
    }
    .al-sidebar .sidebar-hover-elem,
    .al-sidebar .sidebar-select-elem {
        left: 176px;
        -webkit-transition: left .5s ease;
        transition: left .5s ease
    }
}

@media (max-width:500px) {
    .menu-collapsed .al-sidebar {
        width: 0
    }
    .menu-collapsed .sidebar-hover-elem,
    .menu-collapsed .sidebar-select-elem {
        display: none
    }
    .al-main {
        margin-left: 0
    }
    .al-footer {
        padding-left: 0
    }
}

.feed-message,
.feed-panel .panel-body {
    padding: 10px 0
}

.widgets {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.full-width {
    margin: 0 -22px -16px
}

.horizontal-scroll {
    overflow-x: auto;
    overflow-y: visible
}

.panel-subtitle {
    font-size: 15px;
    margin-bottom: 16px
}

.panel-nav {
    position: absolute;
    top: 12px;
    right: 15px;
    z-index: 2
}

.panel-nav i {
    cursor: pointer;
    font-size: 14px;
    color: #bfbfbf;
    margin-left: 11px;
    display: inline-block
}

.panel-nav i.fa-minus {
    margin-bottom: -1px;
    vertical-align: bottom
}

.panel-nav i:hover {
    color: #666
}

#amchart {
    width: 100%;
    height: 350px;
    margin-top: -20px
}

#amChartMap {
    width: 100%;
    height: 315px
}

.feed-message {
    border-bottom: 1px solid rgba(0, 0, 0, .12)
}

.feed-message:first-child {
    padding-top: 0
}

.feed-message .message-icon {
    cursor: pointer;
    width: 60px;
    height: 60px;
    float: left;
    position: relative;
    margin-left: 20px
}

.feed-message .message-icon .media-icon,
.feed-message .message-icon>img {
    border-radius: 30px;
    width: 100%;
    height: 100%
}

.feed-message .message-icon .sub-photo-icon:after {
    content: '';
    display: inline-block;
    width: 22px;
    height: 22px;
    background-size: contain
}

.feed-message .message-icon .sub-photo-icon.video-message {
    background: #e85656
}

.feed-message .message-icon .sub-photo-icon.video-message:after {
    background-image: url(../assets/img/theme/icon/feed/feed-video.svg)
}

.feed-message .message-icon .sub-photo-icon.image-message {
    background: #90b900
}

.feed-message .message-icon .sub-photo-icon.image-message:after {
    width: 21px;
    height: 21px;
    margin-top: 1px;
    margin-left: 1px;
    border-radius: 5px;
    background-image: url(../assets/img/theme/icon/feed/feed-image.svg)
}

.feed-message .message-icon .sub-photo-icon.geo-message {
    background: #e65100
}

.feed-message .message-icon .sub-photo-icon.geo-message:after {
    width: 22px;
    height: 22px;
    background-image: url(../assets/img/theme/icon/feed/feed-location.svg)
}

.feed-message .message-icon .sub-photo-icon {
    display: inline-block;
    padding: 4px;
    position: absolute;
    width: 30px;
    height: 30px;
    right: -2px;
    bottom: -4px;
    border-radius: 15px
}

.feed-message .text-block {
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    margin: 0 0 0 80px;
    padding: 5px 20px;
    color: #666;
    width: 280px;
    height: 70px
}

.feed-message .text-block.text-message {
    font-size: 12px;
    width: inherit;
    max-width: calc(100% - 80px);
    height: inherit;
    min-height: 60px
}

.feed-message .text-block.text-message:before {
    display: block
}

.feed-message .text-block.text-message .message-content {
    font-size: 12px;
    line-height: 15px;
    font-weight: 300
}

.feed-message .text-block.small-message {
    width: 155px;
    height: 145px
}

.feed-message .text-block.small-message .preview {
    bottom: 0;
    top: initial;
    height: 87px
}

.feed-message .text-block.small-message .preview img {
    width: 155px;
    height: 87px;
    border-radius: 0 0 5px 5px
}

.feed-message .message-header {
    font-size: 12px;
    padding-bottom: 5px
}

.feed-message .message-header .author {
    font-size: 13px;
    padding-right: 5px
}

.feed-message .message-content {
    font-size: 18px;
    line-height: 20px
}

.feed-message .preview {
    -webkit-transition: 0s linear all;
    transition: 0s linear all;
    display: inline-block
}

.feed-message .preview img {
    padding-top: 10px;
    width: 100%;
    height: auto;
    float: none!important
}

.feed-message .message-time {
    width: 100%;
    left: 0;
    font-size: 11px;
    padding-top: 10px;
    color: #949494;
    margin-bottom: 5px
}

.feed-message .message-time .post-time {
    float: left
}

.feed-message .message-time .ago-time {
    float: right
}

.fc .fc-toolbar>*>*,
.fc-ltr .fc-popover .fc-header .fc-title,
.fc-rtl .fc-popover .fc-header .fc-close,
.fc-toolbar .fc-left {
    float: left
}

.line-clamp {
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    position: relative;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0!important
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .line-clamp:after {
        content: '...';
        text-align: right;
        bottom: 0;
        right: 0;
        width: 25%;
        display: block;
        position: absolute;
        height: calc(1em * 1.2)
    }
}

/*@supports (-webkit-line-clamp:0) {
    .line-clamp:after {
        display: none!important
    }
}*/

.fc-button-group,
.fc-icon {
    display: inline-block
}

.line-clamp-1 {
    -webkit-line-clamp: 1;
    height: calc(1em * 1.2 * 1)
}

.line-clamp-2 {
    -webkit-line-clamp: 2;
    height: calc(1em * 1.2 * 2)
}

.line-clamp-3 {
    -webkit-line-clamp: 3;
    height: calc(1em * 1.2 * 3)
}

.line-clamp-4 {
    -webkit-line-clamp: 4;
    height: calc(1em * 1.2 * 4)
}

.line-clamp-5 {
    -webkit-line-clamp: 5;
    height: calc(1em * 1.2 * 5)
}

div.blurCalendar {
    font-size: 12px
}

.fc {
    direction: ltr;
    text-align: left
}

.fc button {
    box-sizing: border-box;
    margin: 0;
    height: 2.1em;
    padding: 0 .6em;
    font-size: 1em;
    white-space: nowrap;
    cursor: pointer
}

.fc button::-moz-focus-inner {
    margin: 0;
    padding: 0
}

.fc button .fc-icon {
    position: relative;
    top: 0;
    margin: 0
}

.fc .fc-button-group>* {
    float: left;
    margin: 0 0 0 -1px
}

.fc .fc-button-group>:first-child {
    margin-left: 0
}

.fc hr {
    height: 0;
    margin: 0;
    padding: 0 0 2px;
    border-style: solid;
    border-width: 1px 0
}

.fc-bg table,
.fc-row .fc-highlight-skeleton table {
    height: 100%
}

.fc table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1em
}

.fc th {
    text-align: center
}

.fc td,
.fc th {
    border: 1px solid rgba(255, 255, 255, .3);
    padding: 0;
    vertical-align: top
}

.fc td.fc-today {
    border-style: double
}

.fc .fc-row {
    border: 0 solid
}

.fc .fc-toolbar>*>* {
    margin-left: .75em
}

.fc .fc-toolbar>*>:first-child {
    margin-left: 0
}

.fc .fc-axis {
    vertical-align: middle;
    padding: 0 4px;
    white-space: nowrap
}

.fc-rtl {
    text-align: right
}

.fc-basic-view .fc-week-number,
.fc-toolbar {
    text-align: center
}

.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed hr,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
    border-color: rgba(214, 214, 214, .3)
}

.fc-unthemed .fc-popover {
    background-color: #fff;
    border: 1px solid
}

.fc-unthemed .fc-popover .fc-header,
.fc-unthemed hr {
    background: #eee
}

.fc-unthemed .fc-popover .fc-header .fc-close {
    color: #666;
    font-size: 25px;
    margin-top: 4px
}

.fc-unthemed .fc-today {
    background: rgba(255, 255, 255, .15)
}

.fc-highlight {
    background: rgba(255, 255, 255, .25);
    opacity: .3
}

.fc-icon {
    font-size: 2em;
    font-family: "Courier New", Courier, monospace
}

.fc-icon-left-single-arrow:after {
    content: "\02039";
    font-weight: 700;
    font-size: 100%
}

.fc-icon-right-single-arrow:after {
    content: "\0203A";
    font-weight: 700;
    font-size: 100%
}

.fc-icon-left-double-arrow:after {
    content: "\000AB"
}

.fc-icon-right-double-arrow:after {
    content: "\000BB"
}

.fc-icon-x:after {
    content: "\000D7"
}

.fc-state-default {
    border: 1px solid;
    outline: 0;
    background: repeat-x #f5f5f5;
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0, 0, 0, .1);
    color: #333
}

.fc-state-default.fc-corner-left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.fc-state-default.fc-corner-right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.fc-state-active,
.fc-state-disabled,
.fc-state-down,
.fc-state-hover {
    color: #333;
    background-color: #e2e2e2
}

.fc-state-hover {
    color: #333;
    text-decoration: none;
    background-position: 0 -15px;
    -webkit-transition: background-position .1s linear;
    transition: background-position .1s linear
}

.fc-state-active,
.fc-state-down {
    background: #ccc
}

.fc-state-disabled {
    cursor: default;
    background-image: none;
    opacity: .65;
    box-shadow: none
}

.fc-event.fc-draggable,
.fc-event[href],
.fc-popover .fc-header .fc-close {
    cursor: pointer
}

.fc-popover {
    position: absolute
}

.fc-popover .fc-header {
    padding: 2px 4px
}

.fc-popover .fc-header .fc-title {
    margin: 0 2px
}

.fc-ltr .fc-popover .fc-header .fc-close,
.fc-rtl .fc-popover .fc-header .fc-title {
    float: right
}

.fc-popover>.ui-widget-header+.ui-widget-content {
    border-top: 0
}

.fc-clear {
    clear: both
}

.fc-bg,
.fc-helper-skeleton,
.fc-highlight-skeleton {
    position: absolute;
    top: 0;
    left: 0;
    right: 0
}

.fc-event,
.fc-row {
    position: relative
}

.fc-bg {
    bottom: 0
}

.fc-row table {
    border-left: 0 hidden transparent;
    border-right: 0 hidden transparent;
    border-bottom: 0 hidden transparent
}

.fc-row:first-child table {
    border-top: 0 hidden transparent
}

.fc-row .fc-bg {
    z-index: 1
}

.fc-row .fc-highlight-skeleton {
    z-index: 2;
    bottom: 0
}

.fc-row .fc-highlight-skeleton td {
    border-color: transparent
}

.fc-row .fc-content-skeleton {
    position: relative;
    z-index: 3;
    padding-bottom: 2px
}

.fc-row .fc-helper-skeleton {
    z-index: 4
}

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
    background: 0 0;
    border-color: transparent;
    border-bottom: 0
}

.fc-row .fc-content-skeleton tbody td,
.fc-row .fc-helper-skeleton tbody td {
    border-top: 0
}

.fc-event {
    display: block;
    font-size: .85em;
    line-height: 1.3;
    border: 1px solid #e65100;
    background-color: #e65100;
    font-weight: 400
}

.fc-event,
.fc-event:hover,
.ui-widget .fc-event {
    color: #fff;
    text-decoration: none
}

.fc-day-grid-event {
    margin: 1px 2px 0;
    padding: 0 1px
}

.fc-ltr .fc-day-grid-event.fc-not-start,
.fc-rtl .fc-day-grid-event.fc-not-end {
    margin-left: 0;
    border-left-width: 0;
    padding-left: 1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.fc-ltr .fc-day-grid-event.fc-not-end,
.fc-rtl .fc-day-grid-event.fc-not-start {
    margin-right: 0;
    border-right-width: 0;
    padding-right: 1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.fc-day-grid-event>.fc-content {
    white-space: nowrap;
    overflow: hidden
}

.fc-day-grid-event .fc-time {
    font-weight: 700
}

.fc-day-grid-event .fc-resizer {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 7px
}

.fc-ltr .fc-day-grid-event .fc-resizer {
    right: -3px;
    cursor: e-resize
}

.fc-rtl .fc-day-grid-event .fc-resizer {
    left: -3px;
    cursor: w-resize
}

a.fc-more {
    margin: 1px 3px;
    font-size: .85em;
    cursor: pointer;
    text-decoration: none
}

a.fc-more:hover {
    text-decoration: underline
}

.fc-limited {
    display: none
}

.fc-day-grid .fc-row {
    z-index: 1
}

.fc-more-popover {
    z-index: 2;
    width: 220px
}

.fc-more-popover .fc-event-container {
    padding: 10px
}

.fc-toolbar {
    margin-bottom: 1em
}

.fc-toolbar .fc-right {
    float: right
}

.fc-toolbar .fc-center {
    display: inline-block
}

.fc-toolbar h2 {
    margin: 0;
    font-size: 24px;
    width: 100%;
    line-height: 26px
}

.fc-toolbar button {
    position: relative
}

.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
    z-index: 2
}

.fc-toolbar .fc-state-down {
    z-index: 3
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
    z-index: 4
}

.fc-toolbar button:focus {
    z-index: 5
}

.fc-view-container *,
.fc-view-container :after,
.fc-view-container :before {
    box-sizing: content-box
}

.fc-view,
.fc-view>table {
    position: relative;
    z-index: 1
}

.fc-basicDay-view .fc-content-skeleton,
.fc-basicWeek-view .fc-content-skeleton {
    padding-top: 1px;
    padding-bottom: 1em
}

.fc-basic-view tbody .fc-row {
    min-height: 4em;
    max-height: 70px
}

.fc-row.fc-rigid {
    overflow: hidden
}

.fc-row.fc-rigid .fc-content-skeleton {
    position: absolute;
    top: 0;
    left: 0;
    right: 0
}

.fc-basic-view .fc-day-number,
.fc-basic-view .fc-week-number {
    padding: 0 2px
}

.fc-basic-view td.fc-day-number,
.fc-basic-view td.fc-week-number span {
    padding-top: 2px;
    padding-bottom: 2px
}

.fc-basic-view .fc-week-number span {
    display: inline-block;
    min-width: 1.25em
}

.fc-ltr .fc-basic-view .fc-day-number {
    text-align: right
}

.fc-rtl .fc-basic-view .fc-day-number {
    text-align: left
}

.fc-day-number.fc-other-month {
    opacity: .3
}

.fc-agenda-view .fc-day-grid {
    position: relative;
    z-index: 2
}

.fc-agenda-view .fc-day-grid .fc-row {
    min-height: 3em
}

.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
    padding-top: 1px;
    padding-bottom: 1em
}

.fc-ltr .fc-axis {
    text-align: right
}

.fc-rtl .fc-axis {
    text-align: left
}

.ui-widget td.fc-axis {
    font-weight: 400
}

.fc-time-grid,
.fc-time-grid-container {
    position: relative;
    z-index: 1
}

.fc-time-grid {
    min-height: 100%
}

.fc-time-grid table {
    border: 0 hidden transparent
}

.fc-time-grid>.fc-bg {
    z-index: 1
}

.fc-time-grid .fc-slats,
.fc-time-grid>hr {
    position: relative;
    z-index: 2
}

.fc-time-grid .fc-highlight-skeleton {
    z-index: 3
}

.fc-time-grid .fc-content-skeleton {
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    right: 0
}

.fc-time-grid>.fc-helper-skeleton {
    z-index: 5
}

.fc-slats td {
    height: 1.5em;
    border-bottom: 0
}

.fc-slats .fc-minor td {
    border-top-style: dotted
}

.fc-slats .ui-widget-content {
    background: 0 0
}

.fc-time-grid .fc-highlight-container {
    position: relative
}

.fc-time-grid .fc-highlight {
    position: absolute;
    left: 0;
    right: 0
}

.fc-time-grid .fc-event-container {
    position: relative
}

.fc-ltr .fc-time-grid .fc-event-container {
    margin: 0 2.5% 0 2px
}

.fc-rtl .fc-time-grid .fc-event-container {
    margin: 0 2px 0 2.5%
}

.fc-time-grid .fc-event {
    position: absolute;
    z-index: 1
}

.fc-time-grid-event {
    overflow: hidden
}

.fc-time-grid-event.fc-not-start {
    border-top-width: 0;
    padding-top: 1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.fc-time-grid-event.fc-not-end {
    border-bottom-width: 0;
    padding-bottom: 1px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.fc-body>tr>.fc-widget-content,
.fc-head td,
.fc-head th {
    border: none
}

.fc-time-grid-event>.fc-content {
    position: relative;
    z-index: 2
}

.fc-time-grid-event .fc-title {
    padding: 0 1px
}

.fc-time-grid-event .fc-time {
    padding: 0 1px;
    font-size: .85em;
    white-space: nowrap
}

.fc-time-grid-event .fc-bg {
    z-index: 1;
    background: #fff;
    opacity: .25;
    filter: alpha(opacity=25)
}

.fc-time-grid-event.fc-short .fc-content {
    white-space: nowrap
}

.fc-time-grid-event.fc-short .fc-time {
    display: inline-block;
    vertical-align: top
}

.fc-time-grid-event.fc-short .fc-time span {
    display: none
}

.fc-time-grid-event.fc-short .fc-time:before {
    content: attr(data-start)
}

.fc-time-grid-event.fc-short .fc-time:after {
    content: "\000A0-\000A0"
}

.fc-time-grid-event.fc-short .fc-title {
    display: inline-block;
    vertical-align: top;
    font-size: .85em;
    padding: 0
}

.fc-time-grid-event .fc-resizer {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 0;
    height: 8px;
    overflow: hidden;
    line-height: 8px;
    font-size: 11px;
    font-family: monospace;
    text-align: center;
    cursor: s-resize
}

.fc-time-grid-event .fc-resizer:after {
    content: "="
}

.fc-day-grid-container.fc-scroller {
    height: auto!important
}

.calendar-panel.panel .panel-body {
    padding: 0
}

.fc-head {
    color: #fff;
    background-color: #e65100
}

.fc-head div.fc-widget-header {
    padding: 5px 0
}

.fc-agendaDay-button,
.fc-agendaWeek-button,
.fc-month-button,
.fc-today-button {
    display: none
}

.blurCalendar {
    margin-top: 15px
}

.fc-next-button,
.fc-prev-button {
    position: absolute;
    background: 0 0;
    box-shadow: none;
    border: none;
    color: #666
}

.fc-next-button {
    left: 30px
}

.fc-day-number {
    color: #666;
    opacity: .9
}

.pie-charts {
    color: #666
}

.pie-charts .pie-chart-item-container {
    position: relative;
    padding: 0 15px;
    float: left;
    box-sizing: border-box
}

.pie-charts .pie-chart-item-container .panel {
    height: 114px
}

@media screen and (min-width:1325px) {
    .pie-charts .pie-chart-item-container {
        width: 25%
    }
}

@media screen and (min-width:700px) and (max-width:1325px) {
    .pie-charts .pie-chart-item-container {
        width: 50%
    }
}

@media screen and (max-width:700px) {
    .pie-charts .pie-chart-item-container {
        width: 100%
    }
}

.pie-charts .pie-chart-item {
    position: relative
}

.pie-charts .pie-chart-item .chart-icon {
    position: absolute;
    right: 0;
    top: 3px;
    z-index: -1
}

@media screen and (min-width:1325px) and (max-width:1650px),
(min-width:700px) and (max-width:830px),
(max-width:400px) {
    .pie-charts .chart-icon {
        display: none
    }
}

.pie-charts .chart {
    position: relative;
    display: inline-block;
    width: 84px;
    height: 84px;
    text-align: center;
    float: left
}

.pie-charts .chart canvas {
    position: absolute;
    top: 0;
    left: 0
}

.pie-charts .percent {
    display: inline-block;
    line-height: 84px;
    z-index: 2;
    font-size: 16px
}

.pie-charts .percent:after {
    content: '%';
    margin-left: .1em;
    font-size: .8em
}

.pie-charts .description {
    display: inline-block;
    padding: 20px 0 0 20px;
    font-size: 18px;
    opacity: .9
}

.pie-charts .description .description-stats {
    padding-top: 8px;
    font-size: 24px
}

.pie-charts .angular {
    margin-top: 100px
}

.pie-charts .angular .chart {
    margin-top: 0
}

.panel.popular-app>.panel-body {
    padding: 0
}

.panel.popular-app .popular-app-img-container {
    position: relative;
    padding: 30px 0;
    height: 260px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px
}

.panel.popular-app .popular-app-img-container .popular-app-img {
    width: 260px;
    position: absolute;
    -webkit-transform: translateY(-50%) translate(-50%);
    transform: translateY(-50%) translate(-50%);
    top: 50%;
    left: 50%
}

.panel.popular-app .popular-app-img-container .popular-app-img .logo-text {
    display: inline-block;
    margin: 10px;
    font-size: 26px;
    color: #666
}

.panel.popular-app .popular-app-cost {
    font-size: 20px;
    padding: 20px 22px;
    border-bottom: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc
}

.panel.popular-app .popular-app-info {
    padding: 20px 22px;
    font-size: 20px;
    text-align: center
}

.panel.popular-app .popular-app-info .info-label {
    font-size: 12px
}

.panel.popular-app .row {
    margin: 0
}

.panel.popular-app .row>div {
    padding: 0
}

.blur-theme .panel.popular-app .popular-app-img-container {
    background: rgba(0, 0, 0, .5)
}

#cd-timeline {
    margin-top: 15px;
    position: relative
}

#cd-timeline:before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: #d7e4ed
}

.cd-timeline-block:after,
.cd-timeline-content:after {
    content: "";
    display: table;
    clear: both
}

.cd-timeline-block {
    position: relative;
    margin: 2em 0
}

.cd-timeline-block:first-child {
    margin-top: 0
}

.cd-timeline-block:last-child {
    margin-bottom: 0
}

.cd-timeline-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%
}

@media only screen and (min-width:1170px) {
    .cd-timeline-img {
        width: 60px;
        height: 60px;
        left: 50%;
        margin-left: -30px;
        -webkit-transform: translateZ(0);
        -webkit-backface-visibility: hidden
    }
    .cssanimations .cd-timeline-img.is-hidden {
        visibility: hidden
    }
    .cssanimations .cd-timeline-img.bounce-in {
        visibility: visible;
        -webkit-animation: cd-bounce-1 .6s;
        animation: cd-bounce-1 .6s
    }
}

@-webkit-keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

.cd-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #e65100;
    padding: 1em;
    border-radius: 5px
}

.cd-timeline-content h2 {
    color: #303e49;
    margin: 0;
    font-size: 18px
}

.cd-timeline-content .cd-date,
.cd-timeline-content .cd-read-more {
    font-size: 13px;
    display: inline-block
}

.cd-timeline-content p {
    font-size: 13px;
    margin-bottom: 0
}

.cd-timeline-content .cd-read-more {
    float: right;
    padding: .8em 1em;
    background: #acb7c0;
    color: #fff;
    border-radius: .25em
}

.cd-timeline-content .cd-date {
    float: left;
    padding: .8em 0;
    opacity: .7
}

.cd-timeline-content:before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #fff
}

.cd-timeline-content.warning {
    background: rgba(223, 184, 28, .2)
}

.cd-timeline-content.danger {
    background: rgba(232, 86, 86, .2)
}

.cd-timeline-content.primary {
    background: rgba(32, 158, 145, .2)
}

.no-touch .cd-timeline-content .cd-read-more:hover {
    background-color: #d6d6d6
}

@media only screen and (min-width:1170px) {
    #cd-timeline::before {
        left: 50%;
        margin-left: -2px
    }
    .cd-timeline-block {
        margin: 4em 0
    }
    .cd-timeline-block:first-child {
        margin-top: 0
    }
    .cd-timeline-block:last-child {
        margin-bottom: 0
    }
    .cd-timeline-content {
        margin-left: 0;
        padding: 1.6em;
        width: 42%
    }
    .cd-timeline-content::before {
        top: 24px;
        left: 100%;
        border-color: transparent transparent transparent #fff
    }
    .cd-timeline-content .cd-read-more {
        float: left
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content,
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
        float: right
    }
    .cd-timeline-content .cd-date {
        position: absolute;
        width: 100%;
        left: 137%;
        top: 6px;
        font-size: 16px
    }
    .cd-timeline-content.warning:before {
        border-left-color: rgba(223, 184, 28, .2)
    }
    .cd-timeline-content.danger:before {
        border-left-color: rgba(232, 86, 86, .2)
    }
    .cd-timeline-content.primary:before {
        border-left-color: rgba(32, 158, 145, .2)
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
        top: 24px;
        left: auto;
        right: 100%;
        border-color: transparent #fff transparent transparent
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content.cd-timeline-content.warning::before {
        border-right-color: rgba(223, 184, 28, .2)
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content.cd-timeline-content.danger::before {
        border-right-color: rgba(232, 86, 86, .2)
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content.cd-timeline-content.primary::before {
        border-right-color: rgba(32, 158, 145, .2)
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
        left: auto;
        right: 137%;
        text-align: right
    }
    .cssanimations .cd-timeline-content.is-hidden {
        visibility: hidden
    }
    .cssanimations .cd-timeline-content.bounce-in {
        visibility: visible;
        -webkit-animation: cd-bounce-2 .6s;
        animation: cd-bounce-2 .6s
    }
    .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
        -webkit-animation: cd-bounce-2-inverse .6s;
        animation: cd-bounce-2-inverse .6s
    }
}

@-webkit-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px)
    }
    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        transform: translateX(20px)
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px)
    }
    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        transform: translateX(20px)
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@-webkit-keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        transform: translateX(100px)
    }
    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px)
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        transform: translateX(100px)
    }
    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px)
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

input.task-todo {
    margin-bottom: 8px
}

ul.todo-list {
    margin: 0;
    padding: 0
}

ul.todo-list li {
    margin: 0 0 -1px;
    padding: 12px;
    list-style: none;
    position: relative;
    border: 1px solid #cbcbcb;
    cursor: -webkit-grab;
    cursor: grab;
    height: 42px
}

ul.todo-list li i.remove-todo {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 12px;
    font-size: 32px;
    -webkit-transition: color .2s;
    transition: color .2s;
    color: rgba(203, 203, 203, .5);
    visibility: hidden;
    line-height: 42px
}

ul.todo-list li i.remove-todo:hover {
    color: #cbcbcb
}

ul.todo-list li:hover i.remove-todo {
    visibility: visible
}

ul.todo-list li.checked .todo-text {
    color: #666
}

ul.todo-list li.checked:before {
    background: #cbcbcb!important
}

ul.todo-list li i.mark {
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    height: 42px;
    min-width: 4px;
    background: #cbcbcb;
    cursor: pointer;
    -webkit-transition: min-width .3s ease-out;
    transition: min-width .3s ease-out
}

ul.todo-list li.active i.mark {
    min-width: 40px
}

ul.todo-list li.active label.todo-checkbox>span:before {
    color: #fff;
    content: '\f10c';
    margin-right: 20px;
    -webkit-transition: margin-right .1s ease-out;
    transition: margin-right .1s ease-out;
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
    float: none
}

ul.todo-list li.active label.todo-checkbox>input:checked+span:before {
    content: '\f00c'
}

label.todo-checkbox {
    width: 100%;
    padding-right: 25px;
    min-height: 16px;
    cursor: pointer
}

label.todo-checkbox>span {
    white-space: nowrap;
    height: 16px
}

label.todo-checkbox>span:before {
    border: none;
    color: #949494;
    -webkit-transition: all .15s ease-out;
    transition: all .15s ease-out
}

.add-item-icon {
    display: none
}

.task-todo-container.transparent .todo-panel.panel {
    color: #fff;
    opacity: .9
}

.task-todo-container.transparent input.task-todo {
    color: #fff;
    width: calc(100% - 25px);
    border-radius: 0;
    border: none;
    background: 0 0
}

.task-todo-container.transparent input.task-todo:focus {
    outline: 0;
    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, .12)
}

.task-todo-container.transparent .add-item-icon {
    display: block;
    float: right;
    margin-top: -45px;
    margin-right: 5px;
    font-size: 25px;
    cursor: pointer
}

.task-todo-container.transparent ul.todo-list li {
    margin: 0;
    border: none;
    font-weight: 300
}

.task-todo-container.transparent ul.todo-list li .blur-container {
    height: 40px;
    position: absolute;
    width: calc(100% + 40px);
    top: 0;
    left: -25px;
    overflow-y: hidden
}

.task-todo-container.transparent ul.todo-list li:hover .blur-container {
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, .12)
}

.task-todo-container.transparent ul.todo-list li:hover .blur-box {
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, .3) 0, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, .3) 0, rgba(255, 255, 255, 0) 100%);
    -webkit-filter: blur(3px)
}

.task-todo-container.transparent ul.todo-list li i.remove-todo {
    color: #fff;
    opacity: .4
}

.task-todo-container.transparent ul.todo-list li i.remove-todo:hover {
    color: #fff;
    opacity: .95
}

.task-todo-container.transparent ul.todo-list li i.mark {
    min-width: 40px;
    display: none
}

.task-todo-container.transparent ul.todo-list li label.todo-checkbox>span:before {
    position: absolute;
    color: #666;
    content: '\f10c';
    float: none;
    margin-right: 6px;
    -webkit-transition: none;
    transition: none
}

.task-todo-container.transparent ul.todo-list li.checked label.todo-checkbox>span:before {
    content: '\f00c'
}

.task-todo-container.transparent .box-shadow-border {
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, .12);
    width: calc(100% + 44px);
    margin-left: -22px
}

.channels-block {
    width: 100%;
    position: relative
}

.channels-block.transparent .traffic-chart canvas {
    border: 10px solid rgba(0, 0, 0, .35);
    box-shadow: 0 0 5px 0 #000 inset;
    border-radius: 150px
}

.channels-block.transparent .chart-bg {
    background-color: rgba(0, 0, 0, .35)
}

.chart-bg {
    position: absolute;
    width: 180px;
    height: 180px;
    left: 60px;
    top: 60px;
    border-radius: 100px;
    background-color: transparent
}

.channels-info {
    display: inline-block;
    width: calc(100% - 370px);
    margin-left: 70px;
    margin-top: -20px
}

.small-container .channels-info {
    display: none
}

.channels-info-item p {
    margin-bottom: 9px;
    font-size: 18px;
    opacity: .9
}

.channels-info-item .channel-number {
    display: inline-block;
    float: right
}

.traffic-chart {
    width: 300px;
    position: relative;
    min-height: 300px;
    float: left
}

.traffic-legend {
    display: inline-block;
    padding: 70px 0 0;
    width: 160px
}

.traffic-legend ul.doughnut-legend li {
    list-style: none;
    font-size: 12px;
    margin-bottom: 12px;
    line-height: 16px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 120px
}

.traffic-legend ul.doughnut-legend li span {
    float: left;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px
}

.canvas-holder {
    display: inline-block;
    width: 300px;
    height: 300px;
    position: relative;
    float: left
}

.traffic-text {
    width: 100%;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -24px;
    line-height: 24px;
    text-align: center;
    font-size: 18px
}

.traffic-text span {
    display: block;
    font-size: 18px;
    color: #666
}

.channel-change {
    display: block;
    margin-bottom: 12px
}

.channel-progress {
    height: 4px;
    border-radius: 0;
    width: 100%;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .07);
    box-shadow: none
}

.channel-progress .progress-bar {
    height: 4px;
    background-color: rgba(0, 0, 0, .15);
    box-shadow: none
}

.legend-color {
    width: 30px;
    height: 30px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .25);
    position: relative;
    top: 27px;
    border-radius: 15px;
    left: -45px
}

@media (max-width:768px) {
    div.channels-info {
        display: block;
        width: calc(100% - 88px);
        margin-top: -65px;
        margin-bottom: 10px
    }
    .panel.medium-panel.traffic-panel {
        height: auto
    }
    .traffic-chart {
        position: inherit;
        float: none;
        margin: 0 auto
    }
    .chart-bg {
        left: calc(50% - 90px)
    }
}

@media (max-width:1465px) and (min-width:1199px) {
    .channels-info {
        display: none
    }
    .traffic-chart {
        position: inherit;
        float: none;
        margin: 0 auto
    }
    .chart-bg {
        left: calc(50% - 90px)
    }
}

@media (max-width:380px) {
    .traffic-chart {
        width: 240px
    }
    .canvas-holder {
        width: 240px;
        height: 240px
    }
    .chart-bg {
        top: 30px
    }
}

@media (max-width:320px) {
    .chart-bg {
        left: 50px;
        top: 50px;
        width: 142px;
        height: 142px
    }
}

.weather-wrapper {
    height: 100%
}

.weather-wrapper .weather-main-info {
    min-height: 140px
}

.weather-wrapper .weather-main-info div i {
    display: inline-block;
    width: 48px;
    text-align: center
}

.weather-wrapper .weather-main-info .city-date {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.weather-wrapper .temp-by-time {
    height: calc(100% - 210px)
}

.weather-wrapper .select-day {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 70px
}

.weather-wrapper .select-day .day {
    display: table-cell;
    line-height: 1;
    text-align: center;
    cursor: pointer
}

.weather-wrapper .select-day .day i.weatherIcon {
    -webkit-transition: color .5s ease;
    transition: color .5s ease
}

.weather-wrapper .select-day .day .select-day-info {
    vertical-align: super
}

.weather-wrapper .select-day .day:hover i.weatherIcon {
    color: #40BDE8
}

.weather-wrapper .rightHeading {
    display: block;
    float: right
}

.weather-wrapper .weather-info {
    display: inline-block;
    vertical-align: super
}

.weather-wrapper .font-x1dot25,
.weather-wrapper .font-x1dot5 {
    font-size: 1.5em
}

.weather-wrapper .font-x2 {
    font-size: 2em
}

.weather-wrapper .font-x3 {
    font-size: 3em
}

/*Personnalisation*/
.btn, .btn-sm, .btn-group-sm > .btn{
    border-radius: 20px;
    border-color: #ffffff;
    border-style: solid;
    border-width: 2px;
    opacity: 1.0;
}

.btn:hover {
    border-radius: 10px;
    border-color: #1b867b;
    border-style: solid;
    border-width: 2px;
    opacity: 1;
}

.modal-content {
	border-radius: 0px;
	border-width: 2px;
	border-color: #e65100;
	border-style: solid;
}

.al-share li i {
    cursor: pointer;
    border-radius: 30px;
}

.form-control{
	border-radius: 0px;
}
.ba-img{
	min-height: 30px;
	max-height: 60px;
}

.ba-table-header{
	color: white !important;
	background-color: #e65100;
}

.alert{
	border-radius: 0px;
	color: #e65100 !important
}

.alert-danger{
    color: white !important;
    background-color: #ec0d0d;
}

.panel{
	border-radius: 0px;
}

.panel, .panel-card {
    display: block;
    margin: 15px;
    /*width: 260px;*/
    box-shadow: 0 1px 3px rgba(0,0,0,.19) , 0 1px 2px rgba(0,0,0,.24);
    overflow: hidden;
    color: inherit;
    cursor: pointer;
    transition: all .3s cubic-bezier(.25,.8,.25,1);
    background-color: #fff;
    position: relative;
    padding: 0px;
    margin: 5px;
}

.panel:hover, .panel-card:hover{
    box-shadow: 0 10px 20px #63bbb2, 0 6px 6px rgba(0,0,0,.23);
}

.li-item-preview{
    width: 10%;
    max-height: 150px;
}
.li-item-image-preview {
    width: 12%;
    height: 145px;
    float: left;
    border-radius: 0;
}
.hr{
    margin-top: 10px;
    margin-bottom: 15px;
}

.j_fieldset{
    border-radius: 0px;
    border-width: 1px;
    border-color: #e65100;
    border-style: solid;
    margin-top: 5px;
}

.j_fieldset legend{
    width: auto;
    margin-bottom: 0px;
}
/*PADDINGS*/
.p10{
    padding: 10px;
}
.p5{
    padding: 5px;
}
.p15{
    padding: 15px;
}
.p20{
    padding: 20px;
}
.p25{
    padding: 25px;
}
.p30{
    padding: 30px;
}

.pr2{
    padding-right: 2px;
}

.pr5{
    padding-right: 5px;
}
.pr10{
    padding-right: 10px;
}
.pr15{
    padding-right: 15px;
}
.pr20{
    padding-right: 20px;
}
.pr25{
    padding-right: 25px;
}
.pr30{
    padding-right: 30px;
}

/*MARGINS*/

.m10{
    margin: 10px;
}
.m5{
    margin: 5px;
}
.m15{
    margin: 15px;
}
.m20{
    margin: 20px;
}
.m25{
    margin: 25px;
}
.m30{
    margin: 30px;
}

.mb10{
    margin-bottom: 10px;
}
.mb5{
    margin-bottom: 5px;
}
.mb15{
    margin-bottom: 15px;
}
.mb20{
    margin-bottom: 20px;
}
.mb25{
    margin-bottom: 25px;
}
.mb30{
    margin-bottom: 30px;
}

.mt10{
    margin-top: 10px;
}
.mt5{
    margin-top: 5px;
}
.mt15{
    margin-top: 15px;
}
.mt20{
    margin-top: 20px;
}
.mt25{
    margin-top: 25px;
}
.mt30{
    margin-top: 30px;
}

.mt125{
    margin-top: 125px;
}
.mr10{
    margin-right: 10px;
}
.mr5{
    margin-right: 5px;
}
.mr15{
    margin-right: 15px;
}
.mr20{
    margin-right: 20px;
}
.mr25{
    margin-right: 25px;
}
.mr30{
    margin-right: 30px;
}
/**/
.userpic, .userpic-wrapper {
    border: 1px dashed #b8b8b8;
    width: 200px;
    height: 200px;
    cursor: pointer;
    position: relative;
}



.userpic, .userpic-wrapper {
    width: 200px;
    height: 200px;
}

.form-control{
    height: 25px;
    font-size: 12px;
    padding: 0px 0px 0px 2px;
}

.form-control:focus {
    box-shadow: none;
    border-radius: 1px;
    border-color: #4db1a7;
    background: #fff;
}
.modal-body .form-control:focus {
    box-shadow: none;
    border-radius: 1px;
    border-color: #4db1a7;
    background: #fff;
}
.form-group{
    margin-bottom: 0px;
}

.login-control{
    height: 40px;
}
.text-danger{
    color: #ff0000;
}

.page-top{
    width: 103%;
}

a.btn-link{
    color: #fff !important;
    font-size: 24px;
}

a.btn-link:hover{
    color: #e65100;
}
